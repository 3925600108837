import useTranslation from 'next-translate/useTranslation';
import {useFacebookProvider} from '../../../lib/identity-providers/facebook/use-facebook-provider';
import {
    FACEBOOK_SDK_VERSION,
} from '../../../modules/util/constants';
import {OauthSessionCreateResult} from '../../../modules/person/oauth-session-resource';
import {FacebookIdentityProvider} from '../../../lib/identity-providers/facebook/facebook-provider';
import {facebookSignIn} from '../../../modules/person/facebook-sign-in/facebook-sign-in';
import {BaseConnectedIconicSignInButton} from '../base-connected-button/base-connected-iconic-sign-in-button';
import {FacebookIcon} from '../../common/icons/facebook';

export interface FacebookConnectedSignInButtonProps {
    onSignInSuccess?: (sendToRechargeMe: boolean) => void;
    onSignInFailure?: (e: Error) => Promise<void>;
}

/**
 * This component connects a Facebook button with the sign in logic.
 
 * This component will initialize the Facebook api and, when clicked, will launch the Facebook sign in dialog.
 *
 * If the param email is not present, the login might fail in our server, in that case, we'll
 * redirect the user to the FacebookFullNameRequiredFlow.
 *
 * Loading indicator behaviour:
 *
 * If the login dialog gives away the control of the UI, the loading indicator is not dismissed
 * 1because the invoker of the login flow might want to do something else (like a redirection).
 * - If the login is successful, the loading indicator is not dismissed.
 * - If the login fails, but closeDialogOnFailure is True, the loading indicator is not dismissed.
 * - If the login fails, but closeDialogOnFailure is False, the loading indicator is dismissed.
 *
 * If present, validateInput will be executed before starting the process of signIn with the Apple.
 * If validateInput raised an error, handleValidationError will receive this error as an argument.
 *
 * @param onSignInSuccess
 * @param onSignInFailure
 * @param componentType
 * @constructor
 */
export function ConnectedFacebookSignInButton({
    onSignInSuccess,
    onSignInFailure,
}: FacebookConnectedSignInButtonProps): JSX.Element {
    const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
    const {t} = useTranslation('auth');
    const useFacebookProviderWrapper = (): [
        boolean,
        FacebookIdentityProvider,
        Error
    ] => {
        return useFacebookProvider(FACEBOOK_APP_ID, FACEBOOK_SDK_VERSION);
    };

    const facebookSignInWrapper = async (
        provider: FacebookIdentityProvider
    ): Promise<OauthSessionCreateResult> => {
        return await facebookSignIn(provider, false);
    };

    return BaseConnectedIconicSignInButton<FacebookIdentityProvider>({
        useProvider: useFacebookProviderWrapper,
        iconComponent: <FacebookIcon fill="#3075e8" />,
        signInFunction: facebookSignInWrapper,
        buttonText: t('auth:auth.continue-with-facebook'),
        dataTestId: 'Facebook-button',
        onSignInFailure: onSignInFailure,
        onSignInSuccess: onSignInSuccess,
    });
}
