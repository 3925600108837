import isPropValid from '@emotion/is-prop-valid';
import { transparentize } from 'polished';
import styled from 'styled-components';
import {
    typographyBodyStyles,
    typographyLabelStyles,
    typographySmallBodyStyles,
} from '../typography.styles';

export type StyledInputProps = {
    /**
     * If `true`, the component will be displayed in an error state.
     */
    error?: boolean;
    disabled?: boolean;
};

const customShouldForwardProp = (prop) =>
    isPropValid(prop) && prop !== "error";

export const StyledInput = styled.input.withConfig({
    shouldForwardProp: customShouldForwardProp,
})<StyledInputProps>`
    ${typographyBodyStyles}
    border: none;
    padding: 6px 12px;
    width: 100%;
    background-color: ${({theme, disabled}) =>
        disabled
            ? transparentize(0.4, theme.colors.caballa)
            : transparentize(0.4, theme.colors.arroz)};

    color: ${({theme, disabled}) =>
        disabled ? theme.colors.tiburon : theme.colors.ballena};

    &:focus {
        outline: 0;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: ${({theme}) => theme.colors.tiburon};
    }

    box-sizing: border-box;

    height: ${({theme}) => theme.spacing.xxl};
`;

export type StyledLabelProps = StyledInputProps;
export const StyledLabel = styled.label<StyledLabelProps>`
    ${typographyLabelStyles}
    text-align: left;
`;

export const ErrorText = styled(({ show, ...rest }) => <span {...rest} />)`
    ${typographySmallBodyStyles}
    text-align: left;
    color: ${({theme}) => theme.colors.error};
    height: 20px;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`;

export const TextFieldRoot = styled.div.withConfig({
    shouldForwardProp: customShouldForwardProp,
})<StyledInputProps>`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    vertical-align: top;

    padding-bottom: 0px;

    ${StyledLabel} {
        margin-bottom: ${({theme}) => theme.spacing.xs};
    }

    ${ErrorText} {
        margin-top: ${({theme}) => theme.spacing.xs};
    }
`;

export const InputRoot = styled.div.withConfig({
    shouldForwardProp: customShouldForwardProp,
})<StyledInputProps>`
    display: inline-flex;
    position: relative;
    padding: 1px;
    border: 1px solid
        ${({error, theme}) => (error ? theme.colors.error : theme.colors.atun)};
    border-radius: 4px;
    &,
    & > * {
        background-color: ${({theme, disabled}) =>
            disabled ? theme.colors.caballa : theme.colors.arroz};
    }
`;
