import styled from 'styled-components';
import Centered from './centered';
import {
    typographyHeading1Styles,
    typographyHeading2Styles,
    typographyHeading3Styles,
} from './typography.styles';

export interface Props {
    $textSize?: number;
}

/**
 * Base component for all our headings. It inherits from Centered component
 */
export const BaseHeading = styled(Centered)<Props>``;

/**
 * Component to render styled h1 headings
 */
const H1 = styled(BaseHeading).attrs(() => ({as: 'h1'}))<Props>`
    ${typographyHeading1Styles}
    ${(props) =>
        props.$textSize &&
        `font-size: ${props.$textSize}${props.theme.fontMeasureUnit};`}
`;

/**
 * Component to render styled h2 headings
 */
const H2 = styled(BaseHeading).attrs(() => ({as: 'h2'}))<Props>`
    ${typographyHeading2Styles}
    ${(props) =>
        props.$textSize &&
        `font-size: ${props.$textSize}${props.theme.fontMeasureUnit};`}
`;

const H3 = styled(BaseHeading).attrs(() => ({as: 'h3'}))<Props>`
    ${typographyHeading3Styles}
    ${(props) =>
        props.$textSize &&
        `font-size: ${props.$textSize}${props.theme.fontMeasureUnit};`}
`;

export {H1, H2, H3};
