export function appendMessageToUrl(
    redirectTo: string,
    message?: string,
    messageType?: string,
    overrideMsg?: boolean
): string {
    let redirectUrl: URL;
    try {
        redirectUrl = new URL(redirectTo);
    } catch (e) {
        redirectUrl = new URL(`${process.env.NEXT_PUBLIC_SERVER_BASE_URL}${redirectTo}`);
    }

    if (message) {
        const hasMsg = redirectUrl.searchParams.has('msg');
        // If the redirectUrl doesn't have a message or if we want to override it
        if (!hasMsg || (hasMsg && overrideMsg)) {
            redirectUrl.searchParams.set('msg', message);
            if (messageType) {
                redirectUrl.searchParams.set('msg_type', messageType);
            }
        }
    }
    return redirectUrl.href;
}
