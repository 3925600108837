import {GoogleIdentityProvider} from './google-provider';

/**
 * Once initialized the client, we keep a cached value here
 */
let instance: GoogleIdentityProvider = null;

export function initializeGoogleProvider(
    clientId: string,
    handleSignInResponse,
    handleErrorResponse,
): Promise<any> {
    return new Promise<any>(
        function initializeGoogleProviderPromiseExecutor(resolve, reject) {
            if (instance !== null) {
                return resolve(instance);
            }

            if (!('google' in window)) {
                return reject(
                    new Error(
                        'The google platform script must be loaded before calling this function'
                    )
                );
            }

            const client = window.google.accounts.oauth2.initCodeClient({
                client_id: clientId,
                scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid',
                callback: handleSignInResponse,
                error_callback: handleErrorResponse,
            })
            instance = new GoogleIdentityProvider(client);
            return resolve(instance);
        }
    );
}

/**
 * Sets the cached instance
 * @param provider The provider instance
 */
export function setGoogleProviderInstance(
    provider: GoogleIdentityProvider
): void {
    instance = provider;
}
