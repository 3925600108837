import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useTrack} from '../../../modules/analytics/use-track';
import {PersonAccountType} from '../../../modules/common/models';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import {EVENTS} from '../../../modules/util/constants';
import {DialogAwareFacebookSignInButton} from '../facebook/facebook-sign-in-flow';
import {DialogAwareGoogleSignInButton} from '../google/google-sign-in-flow';
import {DialogAwareAppleSignInButton} from '../apple/apple-sign-in-flow';
import {useLoginDialogContext} from '../login-dialog';
import {BoxContainer, FlexContainer, Heading, P} from '../styles';
import styled from 'styled-components';

type TKeysConst = Record<
    PersonAccountType.GG | PersonAccountType.FB | PersonAccountType.AP,
    {
        readonly heading: string;
        readonly longError: string;
        readonly question: string;
    }
>;

const T_KEYS: TKeysConst = {
    [PersonAccountType.FB]: {
        heading: 'auth:auth.login-failure-heading',
        longError: 'auth:auth.facebook-login-long-error',
        question: 'auth:auth.continue-facebook-prompt',
    },
    [PersonAccountType.GG]: {
        heading: 'auth:auth.login-failure-heading',
        longError: 'auth:auth.google-login-long-error',
        question: 'auth:auth.continue-google-prompt',
    },
    [PersonAccountType.AP]: {
        heading: 'auth:auth.login-failure-heading',
        longError: 'auth:auth.apple-login-long-error',
        question: 'auth:auth.continue-apple-prompt',
    },
};

const Div = styled.div`
    margin-bottom: 40px;
`;

export function LoginFailureOauthAccountScreen({
    type,
}: {
    readonly type:
        | PersonAccountType.GG
        | PersonAccountType.FB
        | PersonAccountType.AP;
}): JSX.Element {
    const {t} = useTranslation('auth');
    const track = useTrack();
    const {email} = useParams<{readonly email?: string}>();
    const {closeDialogOnFailure, closeDialogOnSuccess} =
        useLoginDialogContext();

    useEffect(() => {
        void track(EVENTS.MISMATCH_EMAIL_AND_PERSON_TYPE_MODAL, {
            personType: type,
        });
        
    }, []);

    const {heading, longError, question} = T_KEYS[type];
    return (
        <FlexContainer data-testid="login-failure-oauth-account-screen">
            <Div>
                <Heading>{t(heading)}</Heading>
                <P textAlign="left">
                    <Trans
                        i18nKey={longError}
                        values={{user_email: email}}
                        components={{strong: <strong />}}
                    />
                </P>
                <P textAlign="left">{t(question)}</P>
            </Div>
            <BoxContainer>
                {type === PersonAccountType.FB ? (
                    <DialogAwareFacebookSignInButton
                        closeDialogOnFailure={closeDialogOnFailure}
                        closeDialogOnSuccess={closeDialogOnSuccess}
                        emailAndPersonTypeMismatch={true}
                    />
                ) : type === PersonAccountType.GG ? (
                    <DialogAwareGoogleSignInButton
                        closeDialogOnFailure={closeDialogOnFailure}
                        closeDialogOnSuccess={closeDialogOnSuccess}
                        emailAndPersonTypeMismatch={true}
                    />
                ) : (
                    <DialogAwareAppleSignInButton
                        closeDialogOnFailure={closeDialogOnFailure}
                        closeDialogOnSuccess={closeDialogOnSuccess}
                        emailAndPersonTypeMismatch={true}
                    />
                )}
            </BoxContainer>
        </FlexContainer>
    );
}
