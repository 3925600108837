import styled from 'styled-components';
import {StyledLabel} from '../text-field';
import {typographyLinkBodyStyles} from '../typography.styles';

export const LabelRoot = styled(StyledLabel)`
    display: inline-flex;
    position: relative;
    vertical-align: top;
`;

export const LabelLink = styled.a`
    ${typographyLinkBodyStyles}
    align-self: flex-end;
    color: ${({theme}) => theme.colors.plancton};
    margin-left: auto;
    cursor: pointer;
`;
