import styled from 'styled-components';

interface Props {
    $centered?: boolean;
}

/**
 * Component that conditionally centers its text.
 *
 * It admits the prop `centered`. If it's not set or if it's equal to true, it centers the text.
 *
 * This component is intended to be extended, for example, to create a H1 component we can do
 *
 * ````typescript
 * const H1 = styled(Centered).attrs(() => ({as: 'h1'}))`
 * font-size: ${(props): string => props.fontSize === undefined ?
 * `${props.theme.fontSizes.xLarge}${props.theme.fontMeasureUnit}` :
 * `${props.fontSize}${props.theme.fontMeasureUnit}`};`;
 * ````
 *
 * It can also be used directly
 *
 * ````HTML
 * <Centered>Hello I'm centered</Centered>
 * ````
 *
 * ````html
 * <Centered centered=true>Hello I'm centered</Centered>
 * ````
 *
 * ````html
 * <Centered centered=false>Hello I'm not centered</Centered>
 * ````
 */
const Centered = styled.div<Props>`
    text-align: ${(props): string =>
        props.$centered === undefined
            ? 'center'
            : props.$centered
            ? 'center'
            : 'inherit'};
`;

export default Centered;
