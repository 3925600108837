import React, {
    ChangeEvent,
    FormEvent,
    SyntheticEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import {usePersonDispatch} from '../../../modules/person/context';
import {Button} from '../../buttons/button';
import {useLoginDialogContext} from '../login-dialog';
import {
    BoxContainer,
    CreateNewAccountFormSpacer,
    FlexContainer,
    FullSizeTextField,
    Heading as H2,
    ShowPasswordButton,
    TermsSubtitle,
} from '../styles';
import {
    handleCreateAccountFormSubmit,
    SetErrorFunction,
} from './handle-create-account-form-submit';
import {PAGE_EVENTS} from '../../../modules/util/constants';
import {analytics} from '../../../modules/analytics/analytics';
import {CountryContext} from '../../../modules/login/country-context';

type CreateNewAccountScreenState = {
    readonly fullName: string;
    readonly fullNameError: string;
    readonly password: string;
    readonly passwordError: string;
    readonly showPassword: boolean;
};

const UnderlinedLink = styled.a`
    text-decoration: underline;
`;

const Div = styled.div`
    margin-bottom: 40px;
`;

const Container = styled(FlexContainer)`
    height: 100%;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export function CreateNewAccountScreen(): JSX.Element {
    const country = useContext(CountryContext);
    const {t} = useTranslation('auth');
    const navigate = useNavigate();
    const {hideLoadingIndicator, showLoadingIndicator} =
        useGlobalLoadingIndicator();
    const dispatch = usePersonDispatch();
    const {closeDialog, closeDialogOnSuccess, closeDialogOnFailure} =
        useLoginDialogContext();
    const passwordInputRef = useRef<HTMLInputElement>();
    const fullNameInputRef = useRef<HTMLInputElement>();
    const {email} = useParams<{readonly email: string}>();
    const [screenState, setScreenState] = useState<CreateNewAccountScreenState>(
        {
            fullName: '',
            fullNameError: '',
            password: '',
            passwordError: '',
            showPassword: false,
        }
    );
    const {passwordError, password, fullNameError, fullName, showPassword} =
        screenState;
    const firstUpdate = useRef(true);

    const handleChange = ({target}: ChangeEvent<HTMLInputElement>) => {
        // input name attr must match the object keys
        // removes the error state while the user types
        setScreenState({
            ...screenState,
            [target.name]: target.value,
            [`${target.name}Error`]: '',
        });
    };

    const setError: SetErrorFunction = (fieldName, error) => {
        setScreenState({...screenState, [`${fieldName}Error`]: error});
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleCreateAccountFormSubmit(
            fullName,
            email,
            password,
            country?.featureRechargeMe,
            dispatch,
            setError,
            showLoadingIndicator,
            hideLoadingIndicator,
            closeDialog,
            closeDialogOnSuccess,
            closeDialogOnFailure,
            navigate,
            t
        );
    };

    const toggleShowPassword = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setScreenState({...screenState, showPassword: !showPassword});
    };

    // set focus and put the cursor at the end again.
    // happens only when showPassword changes
    // enqueued for the next "repaint"
    useEffect(() => {
        passwordInputRef.current.selectionStart =
            passwordInputRef.current.selectionEnd = password.length;
        if (firstUpdate.current) {
            fullNameInputRef.current.focus();
        } else {
            passwordInputRef.current.focus();
        }
        firstUpdate.current = false;
    }, [password.length, showPassword]);

    useEffect(() => {
        void analytics.page(PAGE_EVENTS.LOGIN_NEW_ACCOUNT_EMAIL_FORM_PAGE);
    }, []);

    return (
        <Container data-testid="create-new-account-screen">
            <Div>
                <H2>{t('auth:auth.create-new-account-heading')}</H2>
            </Div>
            <BoxContainer>
                <form onSubmit={handleSubmit} noValidate>
                    <FormContainer>
                        <FullSizeTextField
                            defaultValue={email}
                            id="email"
                            label={t('auth:auth.your-email-label')}
                            inputType="email"
                            disabled={true}
                        />
                        <FullSizeTextField
                            ref={fullNameInputRef}
                            value={fullName}
                            id="fullName"
                            name="fullName"
                            label={t('auth:auth.your-full-name')}
                            inputType="text"
                            onChange={handleChange}
                            error={!!fullNameError}
                            errorText={fullNameError}
                        />
                        <FullSizeTextField
                            ref={passwordInputRef}
                            id="password"
                            value={password}
                            onChange={handleChange}
                            label={t('auth:auth.choose-password')}
                            inputType={showPassword ? 'text' : 'password'}
                            error={!!passwordError}
                            errorText={passwordError}
                            name="password"
                            endAdornment={
                                <ShowPasswordButton
                                    onClick={toggleShowPassword}
                                    type="button"
                                    data-testid="show-password-btn"
                                >
                                    {showPassword
                                        ? t('auth:auth.hide-label')
                                        : t('auth:auth.show-label')}
                                </ShowPasswordButton>
                            }
                        />
                        <CreateNewAccountFormSpacer />
                        <Button
                            type="submit"
                            fullWidth
                            data-testid="continue-button"
                        >
                            {t('auth:auth.create-new-account-cta')}
                        </Button>
                        <TermsSubtitle>
                            <Trans
                                i18nKey={'auth:auth.register-accept-terms'}
                                components={[
                                    <span key={1} />,
                                    <UnderlinedLink
                                        key={2}
                                        target="_blank"
                                        rel="noreferrer"
                                        href={'/terms'}
                                    />,
                                ]}
                            />
                        </TermsSubtitle>
                    </FormContainer>
                </form>
            </BoxContainer>
        </Container>
    );
}
