import {BaseConnectedSignInButton} from './base-connected-sign-in-button';
import {BaseConnectedSignInButtonProps} from './base-connected-button-props';

/**
 * Renders a connected primary sign in button.
 *
 * It handles all the common logic of the button (showing the loading indicator,
 * setting/removing the busy status, calling the signIn function, logging in the user,
 * calling the success of failure functions).
 *
 * @param useProvider
 * @param signInFunction
 * @param iconComponent
 * @param buttonText
 * @param dataTestId
 * @param onSignInSuccess
 * @param onSignInFailure
 * @param validateInput: Function that will be executed before starting the process of signIn with the provider.
 * @param handleValidationError: If validateInput raised an error, this function will receive this error as an argument.
 * @constructor
 */
export function BaseConnectedPrimarySignInButton<TIdentityProviderClass>({
    useProvider,
    signInFunction,
    buttonText,
    dataTestId,
    onSignInSuccess,
    onSignInFailure,
    validateInput,
    handleValidationError,
}: BaseConnectedSignInButtonProps<TIdentityProviderClass>): JSX.Element {
    return BaseConnectedSignInButton<TIdentityProviderClass>({
        componentType: 'primary',
        onSignInFailure: onSignInFailure,
        onSignInSuccess: onSignInSuccess,
        signInFunction: signInFunction,
        useProvider: useProvider,
        buttonText: buttonText,
        dataTestId: dataTestId,
        validateInput: validateInput,
        handleValidationError: handleValidationError,
    });
}
