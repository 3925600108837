import {useRollbar} from '@rollbar/react';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserCancelledFlow} from '../../../lib/identity-providers/identity-provider';
import {useTrack} from '../../../modules/analytics/use-track';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import {OauthSessionMissingFacebookEmail} from '../../../modules/person/oauth-session-resource';
import {EVENTS} from '../../../modules/util/constants';
import {BaseDialogAwareSignInButtonErrorHandling} from '../base-dialog-aware-error-handling';
import {BaseDialogAwareSuccessHandling} from '../base-dialog-aware-success-handling';
import {handleUserCancelled} from '../handle-user-cancelled';
import {useLoginDialogContext} from '../login-dialog';
import {ConnectedFacebookSignInButton} from './connected-facebook-sign-in-button';

export interface LoginDialogFacebookSignInButtonProps {
    readonly closeDialogOnSuccess: boolean;
    readonly closeDialogOnFailure: boolean;

    /**
     * Indicates that the user tried to Sign In via Email/Password but the email belongs to a Facebook Account.
     * Defaults to `false`
     */
    readonly emailAndPersonTypeMismatch?: boolean;
}

export function DialogAwareFacebookSignInButton({
    closeDialogOnSuccess,
    closeDialogOnFailure,
    emailAndPersonTypeMismatch = false,
}: LoginDialogFacebookSignInButtonProps): JSX.Element {
    const {closeDialog} = useLoginDialogContext();
    const track = useTrack();
    const {hideLoadingIndicator} = useGlobalLoadingIndicator();
    const navigate = useNavigate();

    const rollbar = useRollbar();
    const handleSuccess = useCallback((sendToRechargeMe: boolean) => {
        BaseDialogAwareSuccessHandling(
            closeDialogOnSuccess,
            closeDialog,
            emailAndPersonTypeMismatch,
            () =>
                void track(
                    EVENTS.LOGIN_IN_MISMATCH_EMAIL_AND_PERSON_TYPE_MODAL,
                    {loginMethod: 'facebook'}
                ),
            sendToRechargeMe,
            navigate
        );
    }, [closeDialog, closeDialogOnSuccess, emailAndPersonTypeMismatch, track]);

    const handleFailure = useCallback(
        async (e: Error) => {
            if (e instanceof UserCancelledFlow) {
                await handleUserCancelled('facebook', track);
            } else {
                rollbar.error('Facebook login failed', e);
            }
            if (e instanceof OauthSessionMissingFacebookEmail) {
                // If the process failed because facebook didn't provide the
                // email, we need to send the user to the screen where we
                // ask for her email.
                navigate('/facebook-email-required', {
                    state: {
                        fbToken: e.fbToken,
                        emailAndPersonTypeMismatch,
                    },
                });
            } else {
                BaseDialogAwareSignInButtonErrorHandling(
                    e,
                    navigate,
                    closeDialog,
                    closeDialogOnFailure,
                    hideLoadingIndicator
                );
            }
        },
        [
            navigate,
            emailAndPersonTypeMismatch,
            closeDialog,
            closeDialogOnFailure,
            hideLoadingIndicator,
            rollbar,
            track,
        ]
    );

    return (
        <ConnectedFacebookSignInButton
            onSignInSuccess={handleSuccess}
            onSignInFailure={handleFailure}
        />
    );
}
