import React, {SyntheticEvent, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {LoginDialogCloseReason, useLoginDialogContext} from './login-dialog';
import {NavPanel} from './nav-panel';

// connects the nav component with the login flow
export function LoginFlowNav(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const {closeDialog} = useLoginDialogContext();

    const handleCloseButtonClick = useCallback(() => {
        closeDialog(LoginDialogCloseReason.CloseButtonClicked);
    }, [closeDialog]);

    const handleBackButtonClick = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            navigate(-1);
        },
        [navigate]
    );

    return (
        <NavPanel
            showBackButton={location.pathname !== '/' && !location.pathname.includes('/set-me-contact')}
            showCloseButton={!location.pathname.includes('/set-me-contact')}
            onCloseButtonClick={handleCloseButtonClick}
            onBackButtonClick={handleBackButtonClick}
        />
    );
}
