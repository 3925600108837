import {
    AppleExtraData,
    AppleIdentityProvider,
} from '../../../lib/identity-providers/apple/apple-provider';
import {OauthSessionCreateResult} from '../oauth-session-resource';
import Cookies from 'universal-cookie';
import {personSignIn} from '../person-sign-in';

export async function appleSignIn(
    provider: AppleIdentityProvider,
    fullName?: string
): Promise<OauthSessionCreateResult> {
    const authResult = await provider.signIn();
    const cookies = new Cookies(document.cookie);
    const queryString = document.location.search;
    if (fullName) {
        if (authResult.extra) {
            authResult.extra.apFullName = fullName;
        } else {
            authResult.extra = {apFullName: fullName};
        }
    }
    return personSignIn<AppleExtraData>(authResult, {
        remember: true,
        cookies,
        queryString,
    });
}
