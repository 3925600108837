import React from 'react';

const ArrowIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.793 11.707a1 1 0 0 1 0-1.414L8.086 6 3.793 1.707A1 1 0 0 1 5.207.293l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0z"
        fill="#648090"
      />
    </svg>
  );
};

export default ArrowIcon;
