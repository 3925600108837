import React, {
    ChangeEvent,
    FormEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import useTranslation from 'next-translate/useTranslation';
import {Button} from '../../buttons/button';
import {
    BoxContainer,
    FlexContainer,
    FullSizeTextField,
    Heading as H2,
} from '../styles';
import {handleEmailFormSubmit} from './handle-email-form-submit';
import {PAGE_EVENTS} from '../../../modules/util/constants';
import {analytics} from '../../../modules/analytics/analytics';
import styled from 'styled-components';

const Div = styled.div`
    margin-bottom: 40px;
`;

export function EmailScreen(): JSX.Element {
    const {t} = useTranslation('auth');
    const navigate = useNavigate();
    const {hideLoadingIndicator, showLoadingIndicator} =
        useGlobalLoadingIndicator();

    const [email, setEmail] = useState('');
    const [errorState, setErrorState] = useState<{
        error: boolean;
        text?: string;
    }>({
        error: false,
    });

    // auto focus
    const emailInputRef = useRef<HTMLInputElement>();
    useEffect(() => {
        emailInputRef.current.focus();
        void analytics.page(PAGE_EVENTS.LOGIN_EMAIL_PAGE);
        
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        if (errorState.error) {
            setErrorState({error: false});
        }
    };

    const setError = (error: boolean, text?: string) => {
        setErrorState({error, text});
        emailInputRef.current.focus();
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return handleEmailFormSubmit(
            email,
            showLoadingIndicator,
            hideLoadingIndicator,
            setError,
            t,
            navigate
        );
    };

    return (
        <FlexContainer data-testid="enter-email-screen">
            <Div>
                <H2>{t('auth:auth.enter-email-heading')}</H2>
            </Div>
            <BoxContainer>
                <form onSubmit={handleSubmit} noValidate>
                    <BoxContainer>
                        <FullSizeTextField
                            ref={emailInputRef}
                            value={email}
                            onChange={handleChange}
                            label={t('auth:auth.your-email-label')}
                            inputType="email"
                            error={errorState.error}
                            errorText={errorState.text}
                            name="email"
                        />
                    </BoxContainer>
                    <BoxContainer>
                        <Button
                            type="submit"
                            fullWidth
                            data-testid="email-screen-continue-btn"
                        >
                            {t('auth:auth.continue-button')}
                        </Button>
                    </BoxContainer>
                </form>
            </BoxContainer>
        </FlexContainer>
    );
}
