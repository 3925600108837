import {NavigateFunction} from 'react-router-dom';
import {
    PersonInfoEmailNotFound,
    personInfoResource,
} from '../../../modules/person/person-info-resource';
import {emailIsInvalid} from '../../../modules/util/email-is-invalid';

export async function handleEmailFormSubmit(
    email: string,
    showLoadingIndicator: () => void,
    hideLoadingIndicator: () => void,
    setError: (error: boolean, text: string) => void,
    t: (key: string) => string,
    navigate: NavigateFunction
): Promise<void> {
    if (email === '') {
        return setError(true, t('auth.enter-email-error'));
    }

    if (emailIsInvalid(email)) {
        return setError(true, t('auth.email-not-valid-error'));
    }

    showLoadingIndicator();
    try {
        await personInfoResource.create({email});
        hideLoadingIndicator();
        navigate(`/login/password/${email}`);
    } catch (e) {
        if (e instanceof PersonInfoEmailNotFound) {
            hideLoadingIndicator();
            navigate(`/login/create/${email}`);
        } else navigate('/general-error', {state: {error: e}});
    }
}
