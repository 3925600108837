import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {analytics} from '../../../../modules/analytics/analytics';
import {PAGE_EVENTS} from '../../../../modules/util/constants';
import {Button} from '../../../buttons/button';
import {BoxContainer, FlexContainer, Heading, P} from '../../styles';

const Div = styled.div`
    margin-bottom: 40px;
`;

export function EmailSentScreen(): JSX.Element {
    const {t} = useTranslation('auth');
    const {email} = useParams<{readonly email?: string}>();
    const navigate = useNavigate();

    useEffect(() => {
        void analytics.page(PAGE_EVENTS.LOGIN_PASSWORD_RESET_EMAIL_PAGE);
        
    }, []);

    return (
        <FlexContainer data-testid="email-sent-screen">
            <Div>
                <Heading>{t('auth:auth.email-sent-heading')}</Heading>
                <P textAlign="center">
                    <Trans
                        i18nKey={'auth:auth.email-sent-long-desc'}
                        values={{user_email: email}}
                        components={{strong: <strong />}}
                    />
                </P>
            </Div>
            <BoxContainer>
                <Button
                    secondary
                    autoFocus
                    type="button"
                    fullWidth
                    tabIndex={1}
                    onClick={() => navigate(`/login/password/${email}`)}
                >
                    {t('auth:auth.login-with-new-password')}
                </Button>
            </BoxContainer>
        </FlexContainer>
    );
}
