import {getLoginUrlParams} from './get-login-url-params';

/**
 * This function returns the URL where we should redirect the user
 * after the login process fails.
 *
 * @param currentUrl
 */
export function getRedirectUrlAfterLoginFailure(currentUrl: string, lang: string): string {
    return getLoginUrlParams(currentUrl, lang).FROM;
}
