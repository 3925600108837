import React from 'react';
import {PersonState} from '../person/state';
import {usePersonState} from '../person/context';
import {track} from './track';

/**
 * Hook to track an event. Its use is preferred over doTrack because it
 * ensures that you have the latest user's data.
 *
 * Because it's a hook, you can only use it as part of a functional component.
 *
 * It returns a function to do the tracking.
 */
export function useTrack(): (
    eventName: string,
    properties?: Record<string, unknown>,
    options?: Record<string, unknown>
) => Promise<void> {
    const person = usePersonState();

    const personRef = React.useRef<PersonState>(person);

    // Listen to changes on person
    React.useEffect(() => {
        personRef.current = person;
        // We don't want to listen to all changes on person, this will cause
        // unnecessary rerenders and for tracking purposes we just need to
        // pay attention to a subset of properties.
        
    }, [person.isLoggedIn, person.person?.currency, person.person?.lang]);

    return async (
        eventName: string,
        properties?: Record<string, unknown>,
        options?: Record<string, unknown>
    ): Promise<void> => {
        await track(personRef.current, eventName, properties, options);
    };
}

export type TrackFn = ReturnType<typeof useTrack>;
