import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { CarrierContext } from '../../modules/login/carrier-context';
import { error } from 'console';
import { Carrier } from '../../modules/common/models/country';
import ArrowIcon from '../common/icons/arrow';
import QuestionMarkIcon from '../common/icons/question-mark';
import { CloseIcon } from '../common/icons/close';
import { mediaQueries } from '../../modules/common/styles';
import { useTranslation } from '../../modules/i18n/use-translation';

const Card = styled.div`
    height: 80px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 12px;
    border-radius: 8px;
    border: solid 1px #d9e0e4;
    background-color: #fff;
    cursor: pointer;
    margin-top: 12px;

    &:last-child {
        margin-bottom: 12px;
    }

`;

const IconBox = styled.div`
    width: 80px;
    height: 56px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 10px;
    border-radius: 8px;
    background-color: ${({theme}) => theme.colors.caballa};
`;

const SelectedIconBox = styled(IconBox)`
    background-color: transparent;
    border-radius: 8px;
    border: solid 1px ${({theme}) => theme.colors.atun};
`;

const QuestionMarkContainer = styled.span`
    font-size: 24px;
    color: ${({theme}) => theme.colors.delfin};
    text-transform: full-width;
`;

const Logo = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const CardText = styled.span`
    flex-grow: 1;
    color: ${({theme}) => theme.colors.ballena};
`;

const SelectedCard = styled(Card)`
    margin-top: 12px;
    cursor: default;
`;

const SelectedCardText = styled(CardText)`
    color: ${({theme}) => theme.colors.planctonLink};
    cursor: default;
`;

const Arrow = styled.span`
    width: 40px;
    height: 40px;
    align-content: center;
    text-align: center;
    line-height: 2.5;
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    ${mediaQueries.lessThan('small')`
        justify-content: start;
    `}
`;

const ModalContent = styled.div`
    background-color: #fff;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    height: calc(80% - 56px);
    overflow: hidden scroll;
    scroll-behavior: smooth;

    ${mediaQueries.lessThan('small')`
        padding: 20px 16px;
        height: calc(100% - 56px);
    `}
`;

const ModalTitle = styled.h2`
    margin: 12px auto 0;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    color: ${({theme}) => theme.colors.ballena};
    max-width: 360px;
`;

const ModalList = styled.div`
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    height: 100%;
    margin-bottom: 20px;
`;

const ModalHeader = styled.div`
    width: 100%;
    max-width: 500px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 15px 0 rgba(0, 44, 72, 0.15);
    background-color: ${({theme}) => theme.colors.arroz};
    position: relative;

    ${mediaQueries.lessThan('small')`
        justify-content: start;
    `}
`;

const TitleHeader = styled.span`
    height: 24px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: ${({theme}) => theme.colors.ballena};

    ${mediaQueries.lessThan('small')`
        margin-left: 16px;
    `}
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 24px;
    height: 24px;
`;

const ButtonContainer = styled.button`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 16px;
    position: absolute;
    color: ${({ theme }) => theme.colors.tiburon};

    ::before {
        content: "";
        background-color: ${({ theme }) => theme.colors.tiburon};
        opacity: 0.15;
    }
`;

const ChangeCarrierLink = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 8px 0 0;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.planctonLink};
    cursor: pointer;
`;

const ErrorLabel = styled(({ show, ...rest }) => <label {...rest} />)`
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.33;
    text-align: left;
    color: ${({ theme }) => theme.colors.error};
    height: 20px;
    margin-top: 8px;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`;

interface CarrierSelectorProps {
    carriers: Carrier[];
    error: boolean;
    errorText: string;
}

const CarrierSelector: React.FC<CarrierSelectorProps> = ({carriers, error, errorText}) => {
    const {t} = useTranslation('auth');
    const {selectedCarrier, setSelectedCarrier} = useContext(CarrierContext);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCardClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleCarrierSelection = (carrier: Carrier) => {
        setSelectedCarrier(carrier);
        setIsModalOpen(false);
    };

    return (
        <>
            {!selectedCarrier &&
                <Card onClick={handleCardClick}>
                    <IconBox>
                        <QuestionMarkContainer><QuestionMarkIcon /></QuestionMarkContainer>
                    </IconBox>
                    <SelectedCardText>{t('auth:auth.choose-carrier')}</SelectedCardText>
                    <Arrow><ArrowIcon /></Arrow>
                </Card>
            }
            {selectedCarrier && 
                <div>
                    <SelectedCard>
                        <SelectedIconBox>
                            <Logo src={selectedCarrier.logoUrl} alt={selectedCarrier.displayName} />
                        </SelectedIconBox>
                        <CardText>{selectedCarrier.displayName}</CardText>
                        <Arrow><ArrowIcon /></Arrow>
                    </SelectedCard>
                    <ChangeCarrierLink onClick={handleCardClick}>{t('auth:auth.change-carrier')}</ChangeCarrierLink>
                </div>
            }
            <ErrorLabel show={error && !selectedCarrier}>{errorText}</ErrorLabel>

            {isModalOpen && (
                <ModalOverlay>
                    <ModalHeader>
                        <TitleHeader>{t('auth:auth.choose-carrier')}</TitleHeader>
                        <ButtonContainer onClick={handleModalClose}>
                            <StyledCloseIcon viewBox="-5 -6 24 24" fill="#2c4826"/>
                        </ButtonContainer>
                        
                    </ModalHeader>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <ModalTitle>{t('auth:auth.choose-carrier')}</ModalTitle>
                        <ModalList>
                            {carriers.map((carrier) => (
                                <Card key={carrier.id} onClick={() => handleCarrierSelection(carrier)}>
                                    <SelectedIconBox>
                                        <Logo src={carrier.logoUrl} alt={carrier.displayName} />
                                    </SelectedIconBox>
                                    <CardText>{carrier.displayName}</CardText>
                                    <Arrow><ArrowIcon /></Arrow>
                                </Card>
                            ))}
                        </ModalList>
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
};

export default CarrierSelector;
