import {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useTrack} from '../../../modules/analytics/use-track';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import {useTranslation} from '../../../modules/i18n/use-translation';
import {EVENTS} from '../../../modules/util/constants';
import {FonomaError} from '../../../modules/util/fonoma-error';
import {
    BoxContainer,
    FlexContainer,
    FullSizeTextField,
    Heading as H1,
    P,
} from '../styles';
import {DialogAwareAppleSignInButton} from './apple-sign-in-flow';
import styled from 'styled-components';

interface AppleFullNameRequiredFlowProps {
    closeDialogOnFailure: boolean;
    closeDialogOnSuccess: boolean;
}

const Container = styled(BoxContainer)`
    margin-bottom: 40px;
`;

export function AppleFullNameRequiredFlow({
    closeDialogOnFailure,
    closeDialogOnSuccess,
}: AppleFullNameRequiredFlowProps): JSX.Element {
    const {t} = useTranslation('auth');
    const trackFn = useTrack();
    const [fullName, setState] = useState<string>('');
    const {hideLoadingIndicator} = useGlobalLoadingIndicator();
    const {
        state: {emailAndPersonTypeMismatch},
    }: {state: {emailAndPersonTypeMismatch: boolean}} = useLocation();

    useEffect(() => {
        // Hide the loading indicator
        hideLoadingIndicator();
        
    }, []);
    const [error, setError] = useState<string>('');

    const validateNotEmpty = useCallback(() => {
        if (!fullName) {
            throw new FonomaError(t('auth:auth.empty-name-error'));
        }
        
    }, [fullName]);

    const handleValidationError = useCallback(
        (e: FonomaError) => {
            setError(e.message);
        },
        [setError]
    );

    useEffect(() => {
        void trackFn(EVENTS.FULL_NAME_NEEDED_MODAL);
        
    }, []);

    return (
        <FlexContainer data-testid="apple-full-name-required">
            <div>
                <H1>{t('auth:auth.apple-full-name-required-title')}</H1>
            </div>
            <Container>
                <P>
                    <strong>
                        {t('auth:auth.apple-full-name-required-subtitle')}
                    </strong>
                </P>
            </Container>
            <BoxContainer>
                <FullSizeTextField
                    error={!!error}
                    errorText={error}
                    label={t('auth:auth.apple-full-name-required-label')}
                    onChange={(e) => setState(e.target.value)}
                    onClick={() => setError(undefined)}
                    inputType={'text'}
                />
            </BoxContainer>
            <BoxContainer>
                <DialogAwareAppleSignInButton
                    closeDialogOnSuccess={closeDialogOnSuccess}
                    closeDialogOnFailure={closeDialogOnFailure}
                    validateInput={validateNotEmpty}
                    handleValidationError={handleValidationError}
                    fullName={fullName}
                    componentType={'primary'}
                    emailAndPersonTypeMismatch={emailAndPersonTypeMismatch}
                />
            </BoxContainer>
        </FlexContainer>
    );
}
