import {LoginUrlParams} from './login-url-params';
import { getLanguageAwareURL } from '../util/get-language-aware-url';

export enum InternalSearchParam {
    RECHARGE_REQUEST_ID = 'rrid',
    PROMO_CODE_ID = 'pcid',
    REDIRECT_TO = 'to',
    REDIRECT_FROM = 'from',
    LANDING_NAME = 'ln'
}

/**
 * Given an URL, returns the LoginUrl params
 * @param currentUrl
 */
export function getLoginUrlParams(currentUrl: string, lang: string): LoginUrlParams {
    const url = new URL(currentUrl);
    const rechargeRequestId = url.searchParams.get(InternalSearchParam.RECHARGE_REQUEST_ID);
    const promoCodeId = url.searchParams.get(InternalSearchParam.PROMO_CODE_ID);
    let redirectTo = url.searchParams.get(InternalSearchParam.REDIRECT_TO);
    let from = url.searchParams.get(InternalSearchParam.REDIRECT_FROM);
    const ln = url.searchParams.get(InternalSearchParam.LANDING_NAME);
    // Cleanup the current querystring, to remove all internals.
    // The remainder of the querystring should be transmitted.
    // Ex: if currentUrl = https://www.fonoma.com/login?utm_source=google&pcid=1234&foo=var,
    // then, after calling cleanUpQueryString(url), we will have that
    // url.search = "?utm_source=google&foo=var";
    cleanUpQueryString(url);
    if (!redirectTo){
        // If the caller is not telling us where to redirect to,
        // we'll redirect to the home page and we will keep all 
        // the non-internal query strings.
        redirectTo = getLanguageAwareURL("/", lang, url.search);
    }
    if (!from){
        // If the caller is not telling us where they want to be redirected to
        // in case the user cancels, we'll redirect to the home page and we will keep all 
        // the non-internal query strings.
        from = getLanguageAwareURL("/", lang, url.search);
    }
    return {
        LANDING_NAME: ln,
        RECHARGE_REQUEST_ID: rechargeRequestId
            ? Number(rechargeRequestId)
            : null,
        PROMO_CODE_ID: promoCodeId,
        FROM: from,
        TO: redirectTo,
    };
}

export function cleanUpQueryString(currentUrl: URL): void{
    Object.values(InternalSearchParam).forEach((searchParam)=>{
        currentUrl.searchParams.delete(searchParam);
    });
}