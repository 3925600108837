import useTranslationBase from 'next-translate/useTranslation';
import type {I18n} from 'next-translate';

export function useTranslation(defaultNs?: string): I18n {
    const {lang, t} = useTranslationBase(defaultNs);
    return {
        t: t,
        lang: lang.slice(0, 2),
    };
}
