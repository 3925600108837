import {NavigateFunction} from 'react-router-dom';
import {LoginDialogCloseReason} from './login-dialog';

/**
 *
 * @param closeDialogOnSuccess Indicates we are closing the Login dialog
 * @param closeDialog The function that closes the dialog
 * @param emailAndPersonTypeMismatch Indicates we are handling and email/person type mismatch
 * @param onEmailAndPersonTypeMismatch The function that handles the 'mismatch' case
 */
export function BaseDialogAwareSuccessHandling(
    closeDialogOnSuccess: boolean,
    closeDialog: (reason: LoginDialogCloseReason) => void,
    emailAndPersonTypeMismatch?: boolean,
    onEmailAndPersonTypeMismatch?: () => void,
    sendToRechargeMe?: boolean,
    navigate?: NavigateFunction,
): void {
    if (emailAndPersonTypeMismatch) {
        onEmailAndPersonTypeMismatch?.();
    }
    if(sendToRechargeMe){
        navigate('/login/set-me-contact/');
    } else if (closeDialogOnSuccess) {
        closeDialog(LoginDialogCloseReason.LoginSuccess);
    }
}
