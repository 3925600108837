import {AxiosResponse} from 'axios';
import {ApiResource, ApiReturn, axiosInstanceV2, NoErrorResponseType, Resource} from '../../lib/resources/resource';
import {FonomaError} from '../util/fonoma-error';
import { Country } from '../common/models/country';

/**
 *  This is what a `get` to the `country` endpoint returns.
 */
export type CountryData = Country & ApiResource;

export class CountryResource extends Resource<
    CountryData,
    never
> {

    translateToError(
        response: AxiosResponse<ApiResource>
    ): FonomaError<ApiReturn> | Error | NoErrorResponseType {
        const apiReturn = response.data.errors;
        if(apiReturn?.[0]) {
            const {message} = apiReturn[0];
            return new FonomaError(message, apiReturn[0]);
        } else {
            return new FonomaError('Connection Error', null); 
        }
    }
}
export const countryResource = new CountryResource('countries', axiosInstanceV2);
