import {AppleIdentityProvider} from './apple-provider';

/**
 * Once initialized the client, we keep a cached value here
 */
let instance: AppleIdentityProvider = null;

/**
 * Initializes the Apple provider.
 * It might return a cached version of the provider.

 * @param clientId
 * @param redirectUri
 */
export function initializeAppleProvider(
    clientId: string,
    redirectUri: string
): AppleIdentityProvider {
    if (instance !== null) {
        return instance;
    }

    if (!('AppleID' in window)) {
        throw new Error(
            'The Apple platform script must be loaded before calling this function'
        );
    }
    // Initialize the AppleID object
    const authInstance = AppleID.auth;
    authInstance.init({
        clientId: clientId,
        redirectURI: redirectUri,
        scope: 'name email',
        usePopup: true,
    });
    instance = new AppleIdentityProvider(authInstance);
    setAppleProviderInstance(instance);
    return instance;
}

/**
 * Sets the cached instance
 * @param provider The provider instance
 */
export function setAppleProviderInstance(
    provider: AppleIdentityProvider
): void {
    instance = provider;
}
