import styled from 'styled-components';
import {transparentize} from 'polished';
import {mediaQueries} from '../../modules/common';
import {IconicButton} from '../buttons/iconic-button';
import {DialogSurface} from '../common/dialog';
import {BackIcon} from '../common/icons/back';
import {CloseIcon} from '../common/icons/close';
import {H2} from '../headings';
import {Button} from '../buttons/button';
import {TextField} from '../text-field';
import {
    typographyBodyStyles,
    typographyHeading2Styles,
    typographyLinkBodyStyles,
} from '../typography.styles';
import React from 'react';
import {shadowBottomLongStyles} from '../shadows.styles';

const navButtonHeight = 24;
const navButtonMargin = 16;
const desktopButtonWidth = 382;

export const Heading = styled(H2)`
    ${typographyHeading2Styles}
    margin: ${(): string => `${80 - navButtonHeight - navButtonMargin}`}px 0 0;
    ${mediaQueries.greaterThan('small')`
     margin: ${(): string =>
         `${120 - navButtonHeight - navButtonMargin}`}px 0 0;
    `};
    color: ${({theme}): string => theme.colors.ballena};
`;

export const BaseParagraph = styled.p<{
    textAlign?: React.CSSProperties['textAlign'];
}>`
    text-align: ${({textAlign}) => textAlign || 'center'};
    margin: 0;
    color: ${({theme}): string => theme.colors.ballena};
    ${typographyBodyStyles}
`;

export const P = styled(BaseParagraph)`
    margin: ${({theme}): string => `${theme.spacing.xl} 0 0`};
    ${mediaQueries.greaterThan('small')`
        max-width: ${() => `${desktopButtonWidth}px`}
    `};
`;

export const TermsSubtitle = styled.span`
    text-align: center;
    color: ${({theme}): string => theme.colors.ballena};
    margin: ${({theme}): string => `${theme.spacing.m} 0 0`};
    width: 100%;
    display: inline-block;
    ${typographyLinkBodyStyles}
    & > a {
        display: inline-block;
    }
`;

export const SigninProviderButton = styled(IconicButton)`
    width: 100%;
    ${mediaQueries.greaterThan('small')`
        width: ${() => `${desktopButtonWidth}px`}
    `};
`;

export const Surface = styled(DialogSurface)`
    width: 100%;
    height: 100%;
    outline: 0;
    ${mediaQueries.greaterThan('small')`
    width: 744px;
    height: 744px;
    ${shadowBottomLongStyles}
  `};
`;

export const StartScreenSpacer = styled.div`
    flex: 1;
    margin-bottom: 40px;
    ${mediaQueries.greaterThan('small')`
        flex: initial;
    `};
`;

export const CreateNewAccountFormSpacer = styled.div`
    margin-top: auto;
    ${mediaQueries.greaterThan('small')`
         margin-top: unset;
    `};
`;

export const NavBackIcon = styled(BackIcon)`
    font-size: 12px;
`;

export const NavCloseIcon = styled(CloseIcon)`
    font-size: 12px;
`;

export const GenericNavButton = styled.a`
    appearance: none;
    outline: 0;
    width: 24px;
    height: ${(): string => `${navButtonHeight}px`};
    background-color: ${({theme}) =>
        transparentize(0.85, theme.colors.tiburon)};
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${({theme}): string => theme.colors.tiburon};
    cursor: pointer;
    &:hover,
    &:focus,
    &:active,
    &:visited {
        text-decoration: none;
        color: ${({theme}): string => theme.colors.tiburon};
    }
`;

export const NavContainer = styled.div`
    display: flex;
    margin: ${(): string =>
        `${navButtonMargin}px ${navButtonMargin}px 0 ${navButtonMargin}px`};
`;

export const NavSpacer = styled.div`
    flex: 1;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${(props): string =>
        `0 ${props.theme.spacing.m} ${props.theme.spacing.m}`};
`;

export const BoxContainer = styled.div`
    ${mediaQueries.greaterThan('small')`
        width: ${() => `${desktopButtonWidth}px`};
    `};
    width: 100%;
`;

export const FullSizeButton = styled(Button)`
    ${mediaQueries.greaterThan('small')`
        max-width: ${() => `${desktopButtonWidth}px`}
    `};
    width: 100%;
`;

export const FullSizeTextField = styled(TextField)`
    width: 100%;
    // 20px of margin bottom, because the input already have a padding-bottom of 24px
    margin-bottom: 8px;
`;

export const ShowPasswordButton = styled.button`
    ${typographyBodyStyles}
    border: none;
    appearance: none;
    padding: 0 12px;
    color: ${({theme}) => theme.colors.ballena};
    outline: none;
    cursor: pointer;
`;
