import React, {
    ChangeEvent,
    FormEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import useTranslation from 'next-translate/useTranslation';
import {usePerson} from '../../../modules/person/context';
import {Button} from '../../buttons/button';
import {useLoginDialogContext} from '../login-dialog';
import {
    BoxContainer,
    CreateNewAccountFormSpacer,
    FlexContainer,
    FullSizeTextField
} from '../styles';
import {H3} from '../../headings';
import {PAGE_EVENTS} from '../../../modules/util/constants';
import {analytics} from '../../../modules/analytics/analytics';
import {typographyBodyStyles} from '../../typography.styles';
import CarrierSelector from '../../buttons/carrier-selector';
import {
    ErrorObj,
    handleSetMeContactFormSubmit,
    SetErrorFunction,
} from './handle-set-me-contact-form-submit';
import {CarrierContext} from '../../../modules/login/carrier-context';
import {MessageBox} from '../../common/message-box';
import {Carrier} from '../../../modules/common/models/country';
import {CountryContext} from '../../../modules/login/country-context';
import {BaseDialogAwareSuccessHandling} from '../base-dialog-aware-success-handling';
import {mediaQueries} from '../../../modules/common/styles';

type MeContactScreenState = {
    readonly phoneNumberPrefix: number;
    readonly phoneNumberSuffix: string;
    readonly carrierId: number;
    readonly planBillingDay?: string;
    readonly phoneNumberSuffixError?: string;
    readonly carrierIdError?: string;
    readonly planBillingDayError?: string;
};

const PHONE_SERVICE_NAME = 'phone';

const SkipLink = styled.a`
    text-decoration: underline;
    margin-top: 16px;
    margin-right: 32px;
    width: 100%;
    text-align: right;
    color: ${({ theme }) => theme.colors.tiburon};
    cursor: pointer;

    ${mediaQueries.lessThan('small')`
        margin-top: 0;
    `}
`;

const Container = styled(FlexContainer)`
    height: calc(100% + 80px);
    overflow-y: auto;
    overflow-x: hidden;
    
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const StyledButton = styled(Button)`
    margin-top: 40px;

    ${mediaQueries.lessThan('small')`
        margin-top: 0;
    `}
`;

export const ShowCountryPrefixLabel = styled.span`
    ${typographyBodyStyles}
    border: none;
    padding: 0 12px;
    color: ${({ theme }) => theme.colors.ballena};
    outline: none;
    font-weight: bold;
    align-content: center;
    line-height: 2.5;
`;

const StyledH3 = styled(H3)`
    margin-top: 26px;
    margin-bottom: 12px;

    ${mediaQueries.lessThan('small')`
        &:first-child {
            margin-top: 24px;
        }
    `}

    ${mediaQueries.greaterThan('small')`
        &:first-child {
            margin-top: 56px;
        }
    `}
`;

const StyledFullSizeTextField = styled(FullSizeTextField)`
    margin-bottom: 0;
    padding-bottom: 0;
`;

const PlanBillingDayInfo = styled.a`
    align-self: stretch;
    font-family: "Inter-Regular", sans-serif;
    font-size: 12px; 
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.colors.tiburon};
    margin-top: -20px;
`;

const SubmitContainer = styled.div`
    padding: 0;
    margin: 0;

    ${mediaQueries.lessThan('small')`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        position: fixed;
        bottom: 0px;
        margin: 0 0 1px -16px;
        padding: 16px;
        background-color: ${({ theme }) => theme.colors.arroz};
        box-shadow: 0 -5px 15px 0 rgba(0,44,72,0.15);
    `}
`;

export function SetMeContactScreen(): JSX.Element {
    const country = useContext(CountryContext);
    const {selectedCarrier, setSelectedCarrier} = useContext(CarrierContext);
    const queryParams = new URLSearchParams(window.location.search);
    const carrierIdParam = queryParams.get("carrier_id");
    const phoneNumberSuffixParam = queryParams.get("phone_number_suffix");

    const {t} = useTranslation('auth');
    const navigate = useNavigate();
    const {hideLoadingIndicator, showLoadingIndicator} =
        useGlobalLoadingIndicator();
    const [personState, dispatch] = usePerson();
    const {closeDialog, closeDialogOnSuccess, closeDialogOnFailure } =
        useLoginDialogContext();
    const planBillingDayInputRef = useRef<HTMLInputElement>();
    const phoneNumberSuffixInputRef = useRef<HTMLInputElement>();

    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [phoneNumberLength, setPhoneNumberLength] = useState<number>(0);
    const [screenState, setScreenState] = useState<MeContactScreenState>(
        {
            phoneNumberPrefix: 0,
            phoneNumberSuffix: '',
            carrierId: 0,
            planBillingDay: '',
        }
    );
    const {
        phoneNumberPrefix, 
        phoneNumberSuffix, 
        planBillingDay, 
        phoneNumberSuffixError, 
        carrierIdError, 
        planBillingDayError
    } = screenState;

    function getPhoneCarriers(service: string): Carrier[] {
        const phoneService = country.services.find(serviceObj => serviceObj.name === service);
        return phoneService ? phoneService.serviceProviders : [];
    }

    function getCarrier(carrier_id: number): Carrier {
        return carriers.find(carrierObj => carrierObj.id === carrier_id);
    }

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        // input name attr must match the object keys
        // removes the error state while the user types
        setScreenState({
            ...screenState,
            [target.name]: target.value,
            [`${target.name}Error`]: '',
        });
    };

    const setError: SetErrorFunction = (errorObj: ErrorObj) => {
        setScreenState({ ...screenState, ...errorObj});
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleSetMeContactFormSubmit(
            personState.person?.id,
            phoneNumberPrefix,
            phoneNumberSuffix,
            selectedCarrier?.id,
            planBillingDay,
            phoneNumberLength,
            dispatch,
            setError,
            showLoadingIndicator,
            hideLoadingIndicator,
            closeDialog,
            closeDialogOnSuccess,
            closeDialogOnFailure,
            navigate,
            t
        );
    };

    const handleSkip = () => {
        dispatch({ type: 'person/LOGIN_SUCCESS', person: personState.person, signup: true });
        BaseDialogAwareSuccessHandling(closeDialogOnSuccess, closeDialog);
    }

    useEffect(() => {
        let newScreenState = {};
        if (country) {
            setPhoneNumberLength(country.cellphoneNumberLength);
            setCarriers(getPhoneCarriers(PHONE_SERVICE_NAME));
            Object.assign(newScreenState, {phoneNumberPrefix: country.phonePrefix });

            if(phoneNumberSuffixParam) {
                Object.assign(newScreenState, {phoneNumberSuffix: phoneNumberSuffixParam});
                phoneNumberSuffixInputRef.current.value = phoneNumberSuffixParam;
            }

            setScreenState({ ...screenState, ...newScreenState});
            hideLoadingIndicator();
        } else {
            setPhoneNumberLength(0);
        }
    }, [country]);

    useEffect(() => {
        if (carrierIdParam && carriers.length) {
            const carrierId = Number.parseInt(carrierIdParam);
            if (!isNaN(carrierId)) {
                const carrier = getCarrier(carrierId);
                if (carrier) {
                    setSelectedCarrier(carrier);
                }
            }
        }
    }, [carriers, carrierIdParam]);
    

    useEffect(() => {
        //Hide Intercom widget just for this modal
        if ((window as any).Intercom) {
            (window as any).Intercom("update", {"hide_default_launcher": true});
        }
        void analytics.page(PAGE_EVENTS.SET_ME_CONTACT_FORM_PAGE);
        phoneNumberSuffixInputRef.current.focus();
    }, []);

    //Select billing day field on select a carrier
    useEffect(() => {
        if (selectedCarrier)
            planBillingDayInputRef.current.select();
    }, [selectedCarrier]);

    return (
        <Container data-testid="set-me-contact-screen">
            <SkipLink onClick={handleSkip}>{t('auth:auth.skip')}</SkipLink>
            <BoxContainer>
                <form onSubmit={handleSubmit} noValidate>
                    <FormContainer>
                        <StyledH3 $centered={false}>{t('auth:auth.cellphone-number-question-h3')}</StyledH3>
                        <StyledFullSizeTextField
                            id="phoneNumberSuffix"
                            name="phoneNumberSuffix"
                            inputType="text"
                            error={!!phoneNumberSuffixError}
                            errorText={phoneNumberSuffixError}
                            startAdornment={
                                <ShowCountryPrefixLabel>
                                    +{phoneNumberPrefix}
                                </ShowCountryPrefixLabel>
                            }
                            onChange={handleChange}
                            ref={phoneNumberSuffixInputRef}
                        />

                        <StyledH3 $centered={false}>{t('auth:auth.carrier-question-h3')}</StyledH3>
                        <CarrierSelector
                            carriers={carriers}
                            errorText={carrierIdError}
                            error={!!carrierIdError} />

                        <StyledH3 $centered={false} className="last-h3">
                            {t('auth:auth.billing-day-question-h3')}
                        </StyledH3>
                        <StyledFullSizeTextField
                            ref={planBillingDayInputRef}
                            id="planBillingDay"
                            value={planBillingDay}
                            onChange={handleChange}
                            inputType="text"
                            error={!!planBillingDayError}
                            errorText={planBillingDayError}
                            name="planBillingDay"
                        />
                        {!planBillingDayError && <PlanBillingDayInfo>{t('auth:auth.billing-day-info')}</PlanBillingDayInfo>}

                        <CreateNewAccountFormSpacer />

                        <SubmitContainer>
                            <StyledButton
                                type="submit"
                                fullWidth
                                data-testid="continue-button"
                            >
                                {t('auth:auth.continue-button')}
                            </StyledButton>
                        </SubmitContainer>
                    </FormContainer>
                </form>
            </BoxContainer>
        </Container>
    );
}
