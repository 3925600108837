import {usePersonDispatch} from '../../../modules/person/context';
import React, {useCallback, useContext, useState} from 'react';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import {SigninProviderButton, FullSizeButton} from '../styles';
import {baseConnectedButtonCallback} from './base-connected-button-callback';
import {BaseConnectedSignInButtonProps} from './base-connected-button-props';
import { CountryContext } from '../../../modules/login/country-context';

/**
 * Base connected sign in button. The param componentType controls how the
 * button will look like.
 *
 * If componentType === 'primary' it will be a typical primary button.
 *
 * If componentType === 'iconic' it will be an iconic button (the caller
 * needs to specify the iconComponent).
 *
 * It handles all the common logic of the button (showing the loading indicator,
 * setting/removing the busy status, calling the signIn function, logging in the user,
 * calling the success of failure functions).
 *
 * @param useProvider
 * @param signInFunction
 * @param iconComponent
 * @param componentType
 * @param buttonText
 * @param dataTestId
 * @param onSignInSuccess
 * @param onSignInFailure
 * @param validateInput: Function that will be executed before starting the process of signIn with the provider.
 * @param handleValidationError: If validateInput raised an error, this function will receive this error as an argument.
 * @constructor
 */
export function BaseConnectedSignInButton<TIdentityProviderClass>({
    useProvider,
    signInFunction,
    iconComponent,
    componentType,
    buttonText,
    dataTestId,
    onSignInSuccess,
    onSignInFailure,
    validateInput,
    handleValidationError,
}: BaseConnectedSignInButtonProps<TIdentityProviderClass>): JSX.Element {
    const personDispatch = usePersonDispatch();
    const [ready, provider] = useProvider();
    const [busy, setBusy] = useState(false);
    const {showLoadingIndicator} = useGlobalLoadingIndicator();
    const country = useContext(CountryContext);

    const handleButtonClick = useCallback(async () => {
        return baseConnectedButtonCallback<TIdentityProviderClass>({
            provider,
            setBusy,
            showLoadingIndicator,
            signInFunction,
            personDispatch,
            onSignInSuccess,
            onSignInFailure,
            validateInput,
            handleValidationError,
            rechargeMeFeature: country?.featureRechargeMe
        });
    }, [
        showLoadingIndicator,
        signInFunction,
        provider,
        personDispatch,
        onSignInSuccess,
        onSignInFailure,
        validateInput,
        handleValidationError,
        country
    ]);
    switch (componentType) {
        case 'iconic':
            return (
                <SigninProviderButton
                    icon={iconComponent}
                    
                    onClick={handleButtonClick}
                    data-testid={dataTestId}
                    disabled={busy || !ready}
                >
                    {buttonText}
                </SigninProviderButton>
            );
        case 'primary':
            return (
                <FullSizeButton
                    
                    onClick={handleButtonClick}
                    data-testid={dataTestId}
                    disabled={busy || !ready}
                    type={'submit'}
                >
                    {buttonText}
                </FullSizeButton>
            );
    }
}
