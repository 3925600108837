import React, {forwardRef, SyntheticEvent} from 'react';
import type {
    ModalHandle,
    ModalProps,
    RenderModalBackdropProps,
} from 'react-overlays/Modal';
import Modal from 'react-overlays/Modal';
import styled from 'styled-components';
import {mediaQueries} from '../../modules/common';

export const StyledBackdrop = styled.div`
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
`;

const Backdrop = (props: RenderModalBackdropProps): JSX.Element => (
    <StyledBackdrop data-testid="modal-backdrop" {...props} />
);

export const DialogRoot = styled(Modal)`
    position: fixed;
    z-index: 1041;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const DialogContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    outline: 0;
    ${mediaQueries.greaterThan('small')`
    justify-content: center;     
    align-items: center;
  `};
`;

export const DialogSurface = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;

    ${mediaQueries.lessThan('small')`
        overflow-y: scroll;
    `}
`;

const Grain = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ${({theme}) => theme.grain?.dialog || ''};
`;

const Gradients = styled.div`
    width: 100%;
    height: 100%;
    content: '';

    ${mediaQueries.greaterThan('small')`
        ${({theme}) => theme.gradients?.dialog || ''};
    `}
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${mediaQueries.lessThan('small')`
        display: none;
    `}
`;

const RelativeContainer = styled.div`
    position: relative;
`;

export interface DialogProps extends ModalProps {
    rootElement?: typeof Modal;
    containerElement?: React.ElementType;
    surfaceElement?: React.ElementType;
}

export const Dialog = forwardRef(function renderDialog(
    {
        renderBackdrop = Backdrop,
        onBackdropClick,
        children,
        rootElement = DialogRoot,
        containerElement = DialogContainer,
        surfaceElement = DialogSurface,
        ...others
    }: DialogProps,
    ref: React.Ref<ModalHandle>
) {
    const onContainerClick = (e: SyntheticEvent): void => {
        if (e.target !== e.currentTarget) {
            return;
        }
        onBackdropClick?.(e);
    };

    const Root = rootElement,
        Container = containerElement,
        Surface = surfaceElement;

    return (
        <Root renderBackdrop={renderBackdrop} ref={ref} {...others}>
            <Container
                onClick={onContainerClick}
                data-testid="dialog-container"
            >
                <Surface>
                    <Background className="background">
                        <Gradients></Gradients>
                        <Grain></Grain>
                    </Background>
                    <RelativeContainer>{children}</RelativeContainer>
                </Surface>
            </Container>
        </Root>
    );
});
