import createSvgIcon from './svg-icon';

export const GoogleIcon = createSvgIcon(
    <g fill="none" fillRule="evenodd">
        <path
            fill="#4285F4"
            d="M20.64 12.20454545c0-.63818181-.0572727-1.25181818-.1636364-1.84090909H12V13.845h4.8436364c-.2086364 1.125-.8427273 2.0781818-1.7959091 2.7163636v2.2581819h2.9086363C19.6581818 17.2527273 20.64 14.9454545 20.64 12.20454545z"
        />
        <path
            fill="#34A853"
            d="M12 21c2.43 0 4.4672727-.8059091 5.9563636-2.1804545l-2.9086363-2.2581819c-.8059091.54-1.8368182.8590909-3.0477273.8590909-2.34409091 0-4.32818182-1.5831818-5.03590909-3.7104545H3.95727273v2.3318182C5.43818182 18.9831818 8.48181818 21 12 21z"
        />
        <path
            fill="#FBBC05"
            d="M6.96409091 13.71c-.18-.54-.28227273-1.11681818-.28227273-1.71 0-.59318182.10227273-1.17.28227273-1.71V7.95818182H3.95727273C3.34772727 9.17318182 3 10.54772727 3 12c0 1.4522727.34772727 2.8268182.95727273 4.0418182L6.9640909 13.71z"
        />
        <path
            fill="#EA4335"
            d="M12 6.57954545c1.3213636 0 2.5077273.45409091 3.4404545 1.3459091l2.5813637-2.58136364C16.4631818 3.89181818 14.4259091 3 12 3 8.48181818 3 5.43818182 5.01681818 3.95727273 7.95818182L6.9640909 10.29C7.67181818 8.16272727 9.65590909 6.57954545 12 6.57954545z"
        />
    </g>,
    'Google'
);
