import {
    AuthResult,
    IdentityProvider,
    IdentityProviders,
} from '../identity-provider';

export const GG_JS_ID = 'google-platform-js';
export const GG_JS_SRC = 'https://accounts.google.com/gsi/client';

export interface GoogleSignInOptions {
    auto?: boolean;
}

/**
 * Google identity provider implementation
 *
 */
export class GoogleIdentityProvider extends IdentityProvider<
    google.accounts.oauth2.CodeClient,
    GoogleSignInOptions
> {
    /**
     * Google sign in
     *
     * Calls gapi.auth2.GoogleAuth.signIn with the defaults.
     * gapi's id_token is in AuthResult.token
     *
     * Autologin:
     * If 'auto' option es true, checks if the google user is already signed in and retrieves the auth data
     * @param options {GoogleSignInOptions}
     */
    async signIn({
        auto = false,
    }: GoogleSignInOptions = {}): Promise<void> {
        try {
            return this.client.requestCode();
        } catch (e) {
            throw e;
        }
    }
}

export const createAuthResult = (idToken: string, autoLogin = false): AuthResult => {
    return {
        token: idToken,
        kind: IdentityProviders.Google,
        isAutoLogin: autoLogin,
    };
}
