import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Dialog} from '../common/dialog';

import {Route} from 'react-router-dom';
import {AppleFullNameRequiredFlow} from './apple/apple-full-name-required-flow';
import {EmailLoginFlow} from './email/email-login-flow';
import {FacebookEmailRequiredFlow} from './facebook/email-required-flow/facebook-email-required-flow';
import {GeneralError} from './general-error';
import {LoginFlow} from './login-flow';
import {StartScreen} from './start-screen';
import {Surface} from './styles';
import {CarrierProvider} from '../../modules/login/carrier-context';
import {CountryProvider} from '../../modules/login/country-context';

export enum LoginDialogCloseReason {
    /**
     * Explicitly closed by the user (Close button clicked)
     */
    CloseButtonClicked = 'CLOSE_BUTTON_CLICKED',

    /**
     * The user clicked outside the dialog ("Backdrop" clicked)
     */
    UserClickedOutside = 'USER_CLICKED_OUTSIDE',

    /**
     * The login flow succeeded and requested to close the dialog (via the dialog context api)
     */
    LoginSuccess = 'LOGIN_SUCCESS',

    /**
     * The login flow failed and requested to close the dialog (via the dialog context api)
     */
    LoginFailure = 'LOGIN_FAILURE',
}

export interface LoginDialogContextType {
    closeDialog: (reason: LoginDialogCloseReason) => void;
    /**
     * If true, we'll close he dialog if the login fails
     */
    readonly closeDialogOnFailure: boolean;

    /**
     * If true, we'll close he dialog if the login succeeds
     */
    readonly closeDialogOnSuccess: boolean;
}

export const LoginDialogContext = createContext<
    LoginDialogContextType | undefined
>(undefined);

export function useLoginDialogContext(): LoginDialogContextType {
    const ctx = useContext(LoginDialogContext);
    if (ctx === undefined) {
        throw new Error(
            'LoginDialogContext can only be used inside the dialog ;)'
        );
    }
    return ctx;
}

interface LoginDialogProps {
    /**
     * Indicates whether to show or close the dialog
     */
    show: boolean;

    /**
     * If true, clicking the backdrop will not fire the onClose callback nor close the dialog.
     */
    disableBackdropClick?: boolean;

    /**
     * Callback fired when the component requests to be closed.
     */
    onClose?: (reason: LoginDialogCloseReason) => void;

    /**
     * If true, we'll close he dialog if the login fails
     */
    closeDialogOnFailure: boolean;

    /**
     * If true, we'll close he dialog if the login succeeds
     */
    closeDialogOnSuccess: boolean;
}

/**
 * Presents the `LoginFlow` using a `Dialog`
 *
 * This component exposes a small api.
 * Since it uses the generic Dialog component, any functionality of the Dialog component
 * can be exposed here (can be implemented if required)
 * @example
 * function MyExamplePage() {
 *
 *     const [show, setShow] = useState(false); *
 *     useEffect(()=> setShow(true));
 *
 *     return <LoginDialog show={show}/>
 * }
 */
export function LoginDialog({
    show,
    onClose,
    disableBackdropClick,
    closeDialogOnFailure,
    closeDialogOnSuccess,
}: LoginDialogProps): JSX.Element {
    const [showDialog, setShowDialog] = useState(show);

    useEffect(() => {
        setShowDialog(show);
    }, [show]);

    const handleBackdropClick = useCallback(
        function handleBackdropClickCallback() {
            if (!disableBackdropClick) {
                onClose?.(LoginDialogCloseReason.UserClickedOutside);
                setShowDialog(false);
            }
        },
        [disableBackdropClick, onClose]
    );

    const dialogContextValue = useMemo<LoginDialogContextType>(() => {
        return {
            closeDialog: (reason): void => {
                onClose?.(reason);
                setShowDialog(false);
            },
            closeDialogOnFailure,
            closeDialogOnSuccess,
        };
    }, [closeDialogOnFailure, closeDialogOnSuccess, onClose]);

    return (
        <Dialog
            surfaceElement={Surface}
            show={showDialog}
            onBackdropClick={handleBackdropClick}
        >
            <LoginDialogContext.Provider value={dialogContextValue}>
            <CountryProvider>
            <CarrierProvider>
                <LoginFlow>
                    <Route
                        path="/"
                        element={
                            <StartScreen
                                closeDialogOnSuccess={closeDialogOnSuccess}
                                closeDialogOnFailure={closeDialogOnFailure}
                            />
                        }
                    />
                    <Route
                        path="/apple-full-name-required"
                        element={
                            <AppleFullNameRequiredFlow
                                closeDialogOnSuccess={closeDialogOnSuccess}
                                closeDialogOnFailure={closeDialogOnFailure}
                            />
                        }
                    />
                    <Route
                        path="/facebook-email-required"
                        element={
                            <FacebookEmailRequiredFlow
                                closeDialogOnSuccess={closeDialogOnSuccess}
                                closeDialogOnFailure={closeDialogOnFailure}
                            />
                        }
                    />
                    <Route path="/login/*" element={<EmailLoginFlow />} />
                    <Route path="/general-error" element={<GeneralError />} />
                    {/* add new flows here */}
                </LoginFlow>
                </CarrierProvider>
                </CountryProvider>
            </LoginDialogContext.Provider>
        </Dialog>
    );
}

export default LoginDialog;
