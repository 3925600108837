import {useRollbar} from '@rollbar/react';
import useTranslation from 'next-translate/useTranslation';
import {FormEvent, useCallback, useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import {useTrack} from '../../../../modules/analytics/use-track';
import {useGlobalLoadingIndicator} from '../../../../modules/global-loading-indicator/context';
import {usePersonDispatch} from '../../../../modules/person/context';
import {EVENTS} from '../../../../modules/util/constants';
import {useLoginDialogContext} from '../../login-dialog';
import {
    BoxContainer,
    FlexContainer,
    FullSizeButton,
    FullSizeTextField,
    Heading as H1,
    P,
} from '../../styles';
import {submitFormCallbackFacebookEmailRequiredFlow} from './submit-form-callback-facebook-email-required-flow';
import {CountryContext} from '../../../../modules/login/country-context';

interface FacebookFullNameRequiredFlowProps {
    closeDialogOnFailure: boolean;
    closeDialogOnSuccess: boolean;
}

const Container = styled(BoxContainer)`
    margin-bottom: 40px;
`;

export function FacebookEmailRequiredFlow({
    closeDialogOnFailure,
    closeDialogOnSuccess,
}: FacebookFullNameRequiredFlowProps): JSX.Element {
    const {t} = useTranslation('auth');
    // Loading indicator hooks
    const {hideLoadingIndicator, showLoadingIndicator} =
        useGlobalLoadingIndicator();

    // Person hooks
    const personDispatch = usePersonDispatch();

    //country context
    const country = useContext(CountryContext);

    // Track function
    const trackFn = useTrack();

    // Dialog related hooks
    const {closeDialog} = useLoginDialogContext();

    const {
        state: {fbToken, emailAndPersonTypeMismatch},
    }: {state: {fbToken: string; emailAndPersonTypeMismatch: boolean}} =
        useLocation();

    const navigate = useNavigate();

    // Email
    const [email, setState] = useState<string>('');

    // Error message
    const [error, setError] = useState<string>('');

    const rollbar = useRollbar();

    // Hide the loading indicator when the component mounts
    useEffect(() => {
        hideLoadingIndicator();
        void trackFn(EVENTS.EMAIL_NEEDED_MODAL);
        
    }, []);

    /**
     * onSubmit handler
     */
    const onSubmitHandler = useCallback(
        (e: FormEvent) => {
            void submitFormCallbackFacebookEmailRequiredFlow({
                e: e,
                t: t,
                fbToken,
                email: email,
                setError: setError,
                showLoadingIndicator: showLoadingIndicator,
                meContactFeature: country?.featureRechargeMe,
                personDispatch: personDispatch,
                cookies: new Cookies(document.cookie),
                queryString: document.location.search,
                closeDialog: closeDialog,
                closeDialogOnFailure: closeDialogOnFailure,
                closeDialogOnSuccess: closeDialogOnSuccess,
                hideLoadingIndicator: hideLoadingIndicator,
                navigate,
                emailAndPersonTypeMismatch,
                rollbar: rollbar,
                onEmailAndPersonTypeMismatch: () =>
                    void trackFn(
                        EVENTS.LOGIN_IN_MISMATCH_EMAIL_AND_PERSON_TYPE_MODAL,
                        {loginMethod: 'facebook'}
                    ),
            });
            return;
        },
        [
            t,
            fbToken,
            emailAndPersonTypeMismatch,
            email,
            showLoadingIndicator,
            country?.featureRechargeMe,
            personDispatch,
            closeDialog,
            closeDialogOnFailure,
            closeDialogOnSuccess,
            hideLoadingIndicator,
            navigate,
            trackFn,
            rollbar,
        ]
    );

    return (
        <FlexContainer data-testid="facebook-email-required">
            <div>
                <H1>{t('auth.facebook-email-required-title')}</H1>
            </div>
            <Container>
                <P>
                    <strong>
                        {t('auth.facebook-email-required-subtitle')}
                    </strong>
                </P>
            </Container>
            <BoxContainer>
                <form data-testid="email-form" onSubmit={onSubmitHandler}>
                    <BoxContainer>
                        <FullSizeTextField
                            error={!!error}
                            errorText={error}
                            label={t('auth.facebook-email-required-label')}
                            onChange={(e) => setState(e.target.value)}
                            onClick={() => setError(undefined)}
                            inputType={'email'}
                        />
                    </BoxContainer>
                    <BoxContainer>
                        <FullSizeButton type={'submit'}>
                            {t('auth.continue-button')}
                        </FullSizeButton>
                    </BoxContainer>
                </form>
            </BoxContainer>
        </FlexContainer>
    );
}
