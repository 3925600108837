import {AxiosRequestConfig} from 'axios';
import {ApiResource, axiosInstanceV2, CreateResource} from '../../lib/resources/resource';
import {Person} from './person-resource';

export type PasswordResetIssueModel = {
    email?: string;
};

export type PasswordResetCompleteModel = {
    passwordResetKey?: string;
    newPassword?: string;
};

export type PasswordResetCreateModel =
    | PasswordResetIssueModel
    | PasswordResetCompleteModel;

export type PasswordResetIssueOut = {
    personEmail: string;
} & ApiResource;

export type PasswordCreateCompleteOut = Person & {redirectUrl: string};

export type PasswordResetCreateOutput =
    | PasswordResetIssueOut
    | PasswordCreateCompleteOut;

export class PasswordResetResource extends CreateResource<
    PasswordResetCreateModel,
    PasswordResetCreateOutput
> {
    async issuePasswordReset(
        model: PasswordResetIssueModel,
        options?: AxiosRequestConfig
    ): Promise<PasswordResetIssueOut> {
        return super.create(model, options);
    }

    async completePasswordReset(
        model: PasswordResetCompleteModel,
        options?: AxiosRequestConfig
    ): Promise<PasswordCreateCompleteOut> {
        return super.create(model, options);
    }
}

export const passwordResetResource = new PasswordResetResource(
    'password_reset',
    axiosInstanceV2
);
