// email regex from http://emailregex.com/
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Check if the email does not matches the pattern. It should work for 99.99% of the emails
 * @param email
 */
export function emailIsInvalid(email: string): boolean {
    return !emailRegex.test(email);
}
