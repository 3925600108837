import {Person} from '../person/person-resource';
import {promoCodeProcessorResource} from './resource';
import {track} from '../analytics/track';
import {EVENTS} from '../util/constants';
import {getResultAndEventAfterPromoCodeProcessingError} from './get-result-and-event-after-promo-code-processing-error';
import {PromoCodeProcessingResult} from './promo-code-processing-result';

/**
 *
 * Process a PromoCode.
 *
 * This function handles processing the promo code, tracking the
 * correct event and providing an URL to redirect the user. The caller's
 * responsibility to redirect the user.
 *
 * It returns a Promise that it's always fulfilled. The returned promise,
 * when fulfilled, gives an object with the type PromoCodeProcessingResult.
 *
 * result.success indicates if the promo code was successfully processed or not.
 * result.redirectTo indicates the URL where the caller should redirect the user.
 * This property can be null in cases of an unknown exception.
 *
 * @param promoCodeId: The Id of the PromoCode.
 * @param personState: The user's state.
 * @param landingName: The landing name (can be undefined)
 */
export async function processPromoCode(
    promoCodeId: string,
    personState: {
        person?: Person;
        isLoggedIn: boolean;
    },
    landingName: string
): Promise<PromoCodeProcessingResult> {
    try {
        const promoCodeResponse = await promoCodeProcessorResource.create({
            promoCodeId: promoCodeId,
        });
        await track(personState, EVENTS.PROMO_CODE_REDEEMED, {
            promoCodeId: promoCodeId,
            userId: personState.person.id,
        });
        if (landingName) {
            await track(personState, EVENTS.PROMO_ACCEPTANCE, {
                accepted: true,
                landingName: landingName,
            });
        }
        return {
            success: true,
            redirectTo: promoCodeResponse.data.afterRedeemUrl,
        };
    } catch (error) {
        const {errorCode, finalResult} =
            
            getResultAndEventAfterPromoCodeProcessingError(error, landingName);
        await track(personState, EVENTS.REDEEM_PROMO_CODE_ERROR, {
            promoCodeId: promoCodeId,
            userId: personState.person.id,
            error_code: errorCode,
        });
        return finalResult;
    }
}
