import React, {MouseEventHandler} from 'react';
import {LabelLink, LabelRoot} from './forgot-password-label.styles';

type ForgotPasswordLabelProps = {
    className?: string;
    linkText?: string;
    labelText?: string;
    htmlFor?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export function ForgotPasswordLabel({
    className,
    htmlFor,
    labelText,
    linkText,
    onClick,
}: ForgotPasswordLabelProps): JSX.Element {
    return (
        <LabelRoot htmlFor={htmlFor} className={className}>
            <span>{labelText}</span>
            <LabelLink onClick={onClick}>{linkText}</LabelLink>
        </LabelRoot>
    );
}
