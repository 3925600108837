import {
    ApiResource,
    ApiReturn,
    axiosInstanceV2,
    NoErrorResponseType,
    Resource,
} from '../../lib/resources/resource';
import {PromoCodeProcessor} from '../common/models/promo-code-processor';
import {AxiosResponse} from 'axios';
import {FonomaError} from '../util/fonoma-error';

export interface PromoCodeProcessorAPI
    extends ApiResource,
        PromoCodeProcessor {}

export class PromoCodeCanNotBeRedeemed extends FonomaError<ApiReturn> {
    errorCode: number;
    constructor(message: string, public wrapped: ApiReturn) {
        super(message);
        this.errorCode = this.wrapped.code;
    }
}

export class PromoCodeProcessorResource extends Resource<PromoCodeProcessorAPI> {
    public translateToError(
        response: AxiosResponse<ApiResource>
    ): FonomaError<ApiReturn> | Error | NoErrorResponseType {
        if (response.data?.errors[0]?.code !== -1) {
            return new PromoCodeCanNotBeRedeemed(
                response.data.errors[0].message,
                response.data.errors[0]
            );
        }
        return super.translateToError(response);
    }
}

export const promoCodeProcessorResource = new PromoCodeProcessorResource(
    'promo_code',
    axiosInstanceV2
);
