import {
    PasswordResetIssueOut,
    passwordResetResource,
} from './password-reset-resource';

export async function issuePasswordReset(
    email: string
): Promise<PasswordResetIssueOut> {
    return passwordResetResource.issuePasswordReset({
        email
    });
}
