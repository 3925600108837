import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {analytics} from '../../../../modules/analytics/analytics';
import {useTrack} from '../../../../modules/analytics/use-track';
import {useGlobalLoadingIndicator} from '../../../../modules/global-loading-indicator/context';
import {issuePasswordReset} from '../../../../modules/person/issue-password-reset';
import {EVENTS, PAGE_EVENTS} from '../../../../modules/util/constants';
import {Button} from '../../../buttons/button';
import {BoxContainer, FlexContainer, Heading, P} from '../../styles';

const Div = styled.div`
    margin-bottom: 40px;
`;

export function PasswordResetScreen(): JSX.Element {
    const {t} = useTranslation('auth');
    const track = useTrack();
    const {email} = useParams<{readonly email?: string}>();
    const navigate = useNavigate();
    const {showLoadingIndicator, hideLoadingIndicator} =
        useGlobalLoadingIndicator();

    const handleClick = async () => {
        showLoadingIndicator();
        try {
            await issuePasswordReset(email);
            hideLoadingIndicator();
            await track(EVENTS.PASSWORD_RESET_REQUEST, {});
            navigate(`/login/email-sent/${email}`);
        } catch (e) {
            navigate('/general-error', {state: {error: e}});
        }
    };

    useEffect(() => {
        void analytics.page(PAGE_EVENTS.LOGIN_PASSWORD_RESET_PAGE);
        
    }, []);

    return (
        <FlexContainer data-testid="password-reset-screen">
            <Div>
                <Heading>{t('auth.reset-password-heading')}</Heading>
                <P textAlign="center">
                    <Trans
                        i18nKey={'auth:auth.reset-password-long-desc'}
                        values={{user_email: email}}
                        components={{strong: <strong />}}
                    />
                </P>
            </Div>
            <BoxContainer>
                <Button
                    autoFocus
                    type="button"
                    fullWidth
                    tabIndex={1}
                    onClick={handleClick}
                    data-testid="reset-password-btn"
                >
                    {t('auth.reset-password-cta')}
                </Button>
            </BoxContainer>
        </FlexContainer>
    );
}
