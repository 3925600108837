import {NavigateFunction} from 'react-router-dom';
import {UserCancelledFlow} from '../../lib/identity-providers/identity-provider';
import {LoginDialogCloseReason} from './login-dialog';

/**
 * Base error handler for DialogAware sign in buttons.
 * If closeDialogOnFailure it will close the dialog. Otherwise,
 * it will redirect the user to the general error screen.
 * @param e
 * @param navigate
 * @param closeDialog
 * @param closeDialogOnFailure
 * @param hideLoadingIndicator
 * @constructor
 */
export function BaseDialogAwareSignInButtonErrorHandling(
    e: Error,
    navigate: NavigateFunction,
    closeDialog: (reason: LoginDialogCloseReason) => void,
    closeDialogOnFailure: boolean,
    hideLoadingIndicator: () => void
): void {
    if (closeDialogOnFailure) {
        closeDialog(LoginDialogCloseReason.LoginFailure);
    } else {
        if (e instanceof UserCancelledFlow) {
            // If the user cancelled the flow, just hide the loading indicator
            // and don't redirect the user.
            hideLoadingIndicator();
            return;
        }
        navigate('/general-error', {state: {error: e}});
    }
}
