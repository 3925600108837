import React, { useState, useEffect, ReactNode } from 'react';
import { Country } from '../common/models/country';
import { countryResource } from '../country/countryResource';

export const CountryContext = React.createContext<Country | undefined>(undefined);

interface CountryProviderProps {
    children: ReactNode;
}

const COUNTRY_CODE_LIST = {
    'fonoma': 'CU',
    'retope': 'US'
};

export function CountryProvider({ children }: CountryProviderProps): JSX.Element {
    const [country, setCountry] = useState<Country | undefined>();

    const fetchCountry = async () => {
        try {
            const countryCode = COUNTRY_CODE_LIST[process.env.NEXT_PUBLIC_PROJECT_NAME];
            const response = await countryResource.get(countryCode);
            if (response.data) {
                setCountry(response.data);
            }
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

    useEffect(() => {
        if (!country) {
            fetchCountry();
        }
    });

    return (
        <CountryContext.Provider value={country}>
            {children}
        </CountryContext.Provider>
    );
}
