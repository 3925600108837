import React, {NamedExoticComponent} from 'react';
import styled from 'styled-components';

interface SvgIconProps extends React.SVGProps<SVGSVGElement> {}

export const SvgIcon = styled.svg.attrs((props: SvgIconProps) => ({
    viewBox: '0 0 24 24',
    focusable: 'false',
    fill: 'currentColor',
    ...props,
}))`
    fill: ${({fill}): string => fill};
    color: inherit;
    font-size: 24px;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    flex-shrink: 0;
`;

// wrappers around native dom elements should pass ref
export default function createSvgIcon(
    path: React.ReactNode,
    displayName: string
): NamedExoticComponent<SvgIconProps> {
    const Component = React.memo<SvgIconProps>(
        React.forwardRef<SVGSVGElement, SvgIconProps>(
            (iconProps: SvgIconProps, ref: React.Ref<SVGSVGElement>) => (
                <SvgIcon
                    data-fnm-test={`${displayName}Icon`}
                    ref={ref}
                    {...iconProps}
                    className={iconProps.className}
                >
                    {path}
                </SvgIcon>
            )
        )
    );

    if (process.env.NODE_ENV !== 'production') {
        Component.displayName = `${displayName}Icon`;
    }
    return Component;
}
