import {useRollbar} from '@rollbar/react';
import useTranslation from 'next-translate/useTranslation';
import {useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
    GoogleIdentityProvider,
    createAuthResult,
} from '../../../lib/identity-providers/google/google-provider';
import {useGoogleProvider} from '../../../lib/identity-providers/google/use-google-provider';
import {UserCancelledFlow} from '../../../lib/identity-providers/identity-provider';
import {useTrack} from '../../../modules/analytics/use-track';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import {usePersonDispatch} from '../../../modules/person/context';
import {googleSignIn} from '../../../modules/person/google-sign-in/google-sign-in';
import {personSignIn} from '../../../modules/person/person-sign-in';
import {EVENTS} from '../../../modules/util/constants';
import {GoogleIcon} from '../../common/icons/google';
import {ConnectedSignInButtonProps} from '../base-connected-button/base-connected-button-props';
import {BaseConnectedIconicSignInButton} from '../base-connected-button/base-connected-iconic-sign-in-button';
import {BaseDialogAwareSignInButtonErrorHandling} from '../base-dialog-aware-error-handling';
import {BaseDialogAwareSuccessHandling} from '../base-dialog-aware-success-handling';
import {handleUserCancelled} from '../handle-user-cancelled';
import {useLoginDialogContext} from '../login-dialog';
import {CountryContext, CountryProvider} from '../../../modules/login/country-context';

/**
 * This component connects the google button with the sign in logic.
 * In the Google case, there is no further steps, the login happens when you click the button
 *
 * This component will initialize the google api and launch the google sign in dialog.
 *
 * Loading indicator behaviour:
 *
 * If the login dialog gives away the control of the UI, the loading indicator is not dismissed
 * 1because the invoker of the login flow might want to do something else (like a redirection).
 * - If the login is successful, the loading indicator is not dismissed.
 * - If the login fails, but closeDialogOnFailure is True, the loading indicator is not dismissed.
 * - If the login fails, but closeDialogOnFailure is False, the loading indicator is dismissed.
 *
 */

export function ConnectedGoogleSignInButton({
    onSignInSuccess,
    onSignInFailure
}: ConnectedSignInButtonProps): JSX.Element {
    const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
    const {t} = useTranslation('auth');
    const personDispatch = usePersonDispatch();
    const {hideLoadingIndicator} = useGlobalLoadingIndicator();
    const country = useContext(CountryContext);

    const handleGoogleSignInResponse = (response, personDispatch) => {
        const cookies = new Cookies(document.cookie);
        const queryString = document.location.search;

        personSignIn(createAuthResult(response.code), {
            remember: true,
            cookies,
            queryString,
        })
            .then((response) => {
                const {signup, person} = response.data;

                const personDispatchType = (signup && country?.featureRechargeMe) ? 
                    'person/SET_ME_CONTACT_REQUEST' : 'person/LOGIN_SUCCESS';
                // update state
                personDispatch({
                    type: personDispatchType,
                    person,
                    signup,
                });
                onSignInSuccess(signup && country?.featureRechargeMe);
            })
            .catch((e) => {
                onSignInFailure(e);
            });
    };

    const handleErrorResponde = (e) => {
        if (e.type === 'popup_closed') {
            hideLoadingIndicator();
            return;
        }
        onSignInFailure(e);
        throw e;
    };

    const useGoogleProviderWrapper = (): [
        boolean,
        GoogleIdentityProvider,
        Error,
    ] => {
        return useGoogleProvider(
            GOOGLE_CLIENT_ID,
            (response) => handleGoogleSignInResponse(response, personDispatch),
            handleErrorResponde
        );
    };

    const googleSignInWrapper = (provider: GoogleIdentityProvider) => {
        return googleSignIn(provider, false);
    };

    return BaseConnectedIconicSignInButton<GoogleIdentityProvider>({
        onSignInSuccess: onSignInSuccess,
        onSignInFailure: onSignInFailure,
        dataTestId: 'google-button',
        buttonText: t('auth:auth.continue-with-google'),
        useProvider: useGoogleProviderWrapper,
        signInFunction: googleSignInWrapper,
        iconComponent: <GoogleIcon />,
    });
}

interface LoginDialogGoogleSignInButtonProps {
    readonly closeDialogOnSuccess: boolean;
    readonly closeDialogOnFailure: boolean;
    readonly emailAndPersonTypeMismatch?: boolean;
    readonly rechargeMeFeature?: boolean;
}

/**
 * Just a dialog-aware signin button
 */
export function DialogAwareGoogleSignInButton({
    closeDialogOnSuccess,
    closeDialogOnFailure,
    emailAndPersonTypeMismatch = false,
}: LoginDialogGoogleSignInButtonProps): JSX.Element {
    const {closeDialog} = useLoginDialogContext();
    const navigate = useNavigate();
    const {hideLoadingIndicator} = useGlobalLoadingIndicator();
    const track = useTrack();

    const rollbar = useRollbar();

    const handleSuccess = useCallback((sendToRechargeMe: boolean) => {
        BaseDialogAwareSuccessHandling(
            closeDialogOnSuccess,
            closeDialog,
            emailAndPersonTypeMismatch,
            () =>
                void track(
                    EVENTS.LOGIN_IN_MISMATCH_EMAIL_AND_PERSON_TYPE_MODAL,
                    {loginMethod: 'google'}
                ),
            sendToRechargeMe,
            navigate,
        );
        
    }, [closeDialog, closeDialogOnSuccess, emailAndPersonTypeMismatch, track]);

    const handleFailure = useCallback(
        async (e: Error) => {
            if (e instanceof UserCancelledFlow) {
                await handleUserCancelled('google', track);
            } else {
                rollbar.error('Google login failed', e);
            }
            BaseDialogAwareSignInButtonErrorHandling(
                e,
                navigate,
                closeDialog,
                closeDialogOnFailure,
                hideLoadingIndicator
            );
        },
        [
            navigate,
            closeDialog,
            closeDialogOnFailure,
            hideLoadingIndicator,
            track,
            rollbar,
        ]
    );

    return (
        <ConnectedGoogleSignInButton
            onSignInSuccess={handleSuccess}
            onSignInFailure={handleFailure}
        />
    );
}
