import {useRollbar} from '@rollbar/react';
import {useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserCancelledFlow} from '../../../lib/identity-providers/identity-provider';
import {useTrack} from '../../../modules/analytics/use-track';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import {OauthSessionAutologinFailed} from '../../../modules/person/oauth-session-resource';
import {EVENTS} from '../../../modules/util/constants';
import {FonomaError} from '../../../modules/util/fonoma-error';
import {BaseDialogAwareSignInButtonErrorHandling} from '../base-dialog-aware-error-handling';
import {BaseDialogAwareSuccessHandling} from '../base-dialog-aware-success-handling';
import {handleUserCancelled} from '../handle-user-cancelled';
import {useLoginDialogContext} from '../login-dialog';
import {ConnectedAppleSignInButton} from './connected-apple-sign-in-button';
import { CountryContext } from '../../../modules/login/country-context';

export interface LoginDialogAppleSignInButtonProps {
    closeDialogOnSuccess: boolean;
    closeDialogOnFailure: boolean;
    componentType?: 'iconic' | 'primary';
    fullName?: string;
    validateInput?: () => void;
    handleValidationError?: (e: FonomaError) => void;
    emailAndPersonTypeMismatch?: boolean;
}

/**
 * This component connects an apple button with the sign in logic.

 * This component will initialize the Apple api and, when clicked, will launch the Apple sign in dialog.
 *
 * If the param fullName is not present, the login might fail in our server, in that case, we'll
 * redirect the user to the AppleFullNameRequiredFlow.
 *
 * Loading indicator behaviour:
 *
 * If the login dialog gives away the control of the UI, the loading indicator is not dismissed
 * 1because the invoker of the login flow might want to do something else (like a redirection).
 * - If the login is successful, the loading indicator is not dismissed.
 * - If the login fails, but closeDialogOnFailure is True, the loading indicator is not dismissed.
 * - If the login fails, but closeDialogOnFailure is False, the loading indicator is dismissed.
 *
 * If present, validateInput will be executed before starting the process of signIn with the Apple.
 * If validateInput raised an error, handleValidationError will receive this error as an argument.
 *
 */
export function DialogAwareAppleSignInButton({
    closeDialogOnSuccess,
    closeDialogOnFailure,
    fullName,
    componentType = 'iconic',
    validateInput,
    handleValidationError,
    emailAndPersonTypeMismatch = false,
}: LoginDialogAppleSignInButtonProps): JSX.Element {
    const {closeDialog} = useLoginDialogContext();
    const {hideLoadingIndicator} = useGlobalLoadingIndicator();
    const navigate = useNavigate();
    const track = useTrack();

    const rollbar = useRollbar();

    const handleSuccess = useCallback((sendToRechargeMe: boolean) => {
        BaseDialogAwareSuccessHandling(
            closeDialogOnSuccess,
            closeDialog,
            emailAndPersonTypeMismatch,
            () =>
                void track(
                    EVENTS.LOGIN_IN_MISMATCH_EMAIL_AND_PERSON_TYPE_MODAL,
                    {loginMethod: 'apple'}
                ),
            sendToRechargeMe,
            navigate,
        );
    }, [closeDialog, closeDialogOnSuccess, emailAndPersonTypeMismatch, track]);

    const handleFailure = useCallback(
        async (e: Error) => {
            if (e instanceof UserCancelledFlow) {
                await handleUserCancelled('apple', track);
            } else {
                rollbar.error('Apple login failed', e);
            }
            if (e instanceof OauthSessionAutologinFailed) {
                // If the autologin failed, we need to send the user to
                // the screen where we ask for their full name.
                navigate('/apple-full-name-required', {
                    state: {
                        emailAndPersonTypeMismatch,
                    },
                });
            } else {
                BaseDialogAwareSignInButtonErrorHandling(
                    e,
                    navigate,
                    closeDialog,
                    closeDialogOnFailure,
                    hideLoadingIndicator
                );
            }
        },
        [
            track,
            rollbar,
            navigate,
            emailAndPersonTypeMismatch,
            closeDialog,
            closeDialogOnFailure,
            hideLoadingIndicator,
        ]
    );

    return (
        <ConnectedAppleSignInButton
            onSignInSuccess={handleSuccess}
            onSignInFailure={handleFailure}
            componentType={componentType}
            validateInput={validateInput}
            handleValidationError={handleValidationError}
            fullName={fullName}
        />
    );
}
