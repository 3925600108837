import {useLocation} from 'react-router-dom';
import {useTranslation} from '../../modules/i18n/use-translation';
import {useGlobalLoadingIndicator} from '../../modules/global-loading-indicator/context';
import {useEffect} from 'react';
import {FonomaError} from '../../modules/util/fonoma-error';
import {BoxContainer, FlexContainer, Heading as H1, P} from './styles';
import styled from 'styled-components';

const Container = styled(BoxContainer)`
    margin-bottom: 40px;
`;

export function GeneralError(): JSX.Element {
    const {t} = useTranslation('auth');
    const {state}: {state: {error: Error}} = useLocation();
    const {hideLoadingIndicator} = useGlobalLoadingIndicator();

    useEffect(() => {
        // Hide the loading indicator
        hideLoadingIndicator();
        
    }, []);

    let message: string;

    if (state.error instanceof FonomaError) {
        // If the error is a FonomaErrors, we show the message (it's already translated)
        message = state.error.message;
    } else {
        // If the error is not a fonoma error, we show a generic message
        message = t('auth:auth.general-error');
    }

    return (
        <FlexContainer data-testid="general-error">
            <div>
                <H1>{t('auth:auth.login-failure-title')}</H1>
            </div>
            <Container>
                <P>{message}</P>
            </Container>
        </FlexContainer>
    );
}
