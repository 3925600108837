import React from 'react';
import {useNavigate} from 'react-router-dom';
import useTranslation from 'next-translate/useTranslation';
import {EnvelopeIcon} from '../../common/icons/envelope';
import {SigninProviderButton} from '../styles';

/**
 * Connected to the router
 * Starts the flow
 */
export function ConnectedEmailButton(): JSX.Element {
    const navigate = useNavigate();
    const {t} = useTranslation('auth');
    const handleClick = () => {
        navigate('/login');
    };

    return (
        <SigninProviderButton
            onClick={handleClick}
            icon={<EnvelopeIcon />}
            data-testid="email-button"
        >
            {t('auth:auth.continue-with-email')}
        </SigninProviderButton>
    );
}
