import React, {SyntheticEvent} from 'react';
import {
    GenericNavButton,
    NavBackIcon,
    NavCloseIcon,
    NavContainer,
} from './styles';
import styled from 'styled-components';

interface NavPanelProps {
    onBackButtonClick?: (e: SyntheticEvent) => void;
    onCloseButtonClick?: (e: SyntheticEvent) => void;
    showBackButton: boolean;
    showCloseButton?: boolean;
}

const Div = styled.div`
    flex: 1;
`;

export function NavPanel({
    onBackButtonClick,
    onCloseButtonClick,
    showBackButton,
    showCloseButton = true
}: NavPanelProps): JSX.Element {
    return (
        <NavContainer>
            {showBackButton && (
                <GenericNavButton
                    onClick={onBackButtonClick}
                    data-testid="navpanel-back-button"
                >
                    <NavBackIcon viewBox="0 0 12 12" fill="none" />
                </GenericNavButton>
            )}
            <Div />
            {showCloseButton &&
                <GenericNavButton
                    onClick={onCloseButtonClick}
                    data-testid="navpanel-close-button"
                >
                    <NavCloseIcon viewBox="0 0 12 12" fill="none" />
                </GenericNavButton>
            }
        </NavContainer>
    );
}
