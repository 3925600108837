import {AxiosResponse} from 'axios';
import {ApiResource, ApiReturn, axiosInstanceV2, Resource} from '../../lib/resources/resource';
import {FonomaError} from '../util/fonoma-error';

export type PersonInfoCreateOut = {
    emailFound: boolean;
} & ApiResource;

export type PersonInfoCreateIn = {
    email: string;
};

export class PersonInfoEmailNotFound extends FonomaError<ApiReturn> {}

export class PersonInfoResource extends Resource<
    never,
    never,
    PersonInfoCreateIn,
    PersonInfoCreateOut
> {
    static readonly FONOMA_EMAIL_NOT_FOUND = -2;

    translateToError(
        response: AxiosResponse<ApiResource>
    ): FonomaError<ApiReturn> {
        if(response.data.errors?.length > 0) {
            const error = response.data.errors[0];
            if(error.code === PersonInfoResource.FONOMA_EMAIL_NOT_FOUND)
                return new PersonInfoEmailNotFound(error.message, error);
            else
                return new FonomaError(error.message, error);
        }

        return new FonomaError(
            `Unknown error,
            Message: Unknown error,
            HTTP Status: Unknown`
        );
    }
}

export const PERSON_INFO_RESOURCE_NAME = 'person_info';

/**
 * Default resource instance ready to use
 */
export const personInfoResource = new PersonInfoResource(
    PERSON_INFO_RESOURCE_NAME,
    axiosInstanceV2
);
