import createSvgIcon from './svg-icon';

export const AppleIcon = createSvgIcon(
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
            fill="#000"
            fillRule="nonzero"
            d="M19.814648 21c-.996 1.488-2.052 2.94-3.66 2.964-1.608.036-2.124-.948-3.948-.948-1.836 0-2.40000001.924-3.92394001.984-1.572.06-2.76-1.584-3.768-3.036-2.052-2.964-3.624-8.424-1.512-12.096 1.044-1.824 2.916-2.976 4.944-3.012C9.48264799 5.832 10.946648 6.9 11.894648 6.9c.936 0 2.712-1.284 4.572-1.092.78.036 2.964.312 4.368 2.376-.108.072-2.604 1.536-2.58 4.572.036 3.624 3.18 4.836 3.216 4.848-.036.084-.504 1.728-1.656 3.396zm-6.852-19.2c.876-.996 2.328-1.752 3.528-1.8.156 1.404-.408 2.82-1.248 3.828-.828 1.02-2.196 1.812-3.54 1.704-.18-1.38.492-2.82 1.26-3.732z"
        />
    </g>,
    'Apple'
);
