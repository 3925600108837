import {OauthSessionCreateResult} from '../../../modules/person/oauth-session-resource';
import {PersonDispatch} from '../../../modules/person/context';
import {FonomaError} from '../../../modules/util/fonoma-error';

export interface Params<TIdentityProviderClass> {
    provider: TIdentityProviderClass;
    setBusy: (boolean) => void;
    showLoadingIndicator: () => void;
    signInFunction: (
        provider: TIdentityProviderClass
    ) => Promise<OauthSessionCreateResult> | Promise<void>;
    personDispatch: PersonDispatch;
    onSignInSuccess?: (boolean) => void;
    onSignInFailure?: (e: Error) => Promise<void>;
    validateInput?: () => void;
    handleValidationError?: (e: FonomaError) => void;
    rechargeMeFeature?: boolean
}

/**
 * It handles all the common logic of the button (calling the validation functions, showing the loading indicator,
 * setting/removing the busy status, calling the signIn function, logging in the user,
 * calling the success of failure functions).
 *
 * Intended to be used as the callback on connected buttons.
 *
 * @param provider
 * @param setBusy
 * @param showLoadingIndicator
 * @param signInFunction
 * @param personDispatch
 * @param onSignInSuccess
 * @param onSignInFailure
 * @param validateInput: Function that will be executed before starting the process of signIn with the provider.
 * @param handleValidationError: If validateInput raised an error, this function will receive this error as an argument.
 */
export async function baseConnectedButtonCallback<TIdentityProviderClass>({
    provider,
    setBusy,
    validateInput,
    handleValidationError,
    showLoadingIndicator,
    signInFunction,
    personDispatch,
    onSignInFailure,
    onSignInSuccess,
    rechargeMeFeature
}: Params<TIdentityProviderClass>): Promise<void> {
    setBusy(true);
    if (validateInput) {
        try {
            validateInput();
        } catch (e) {
            // removing the lock
            setBusy(false);
            
            handleValidationError(e);
            return;
        }
    }
    try {
        // Loading indicator
        showLoadingIndicator();
        // rpc
        const result = await signInFunction(provider);
        if (result) {
            const {signup, person} = result.data

            const personDispatchType = (signup && rechargeMeFeature) ? 
                'person/SET_ME_CONTACT_REQUEST' : 'person/LOGIN_SUCCESS';
            // update state
            personDispatch({
                type: personDispatchType,
                person,
                signup,
            });

            // local state
            onSignInSuccess?.(signup && rechargeMeFeature);
        }

        setBusy(false);
    } catch (e) {
        setBusy(false);
        
        await onSignInFailure?.(e);
    }
}
