import {NavigateFunction} from 'react-router-dom';
import {PersonDispatch} from '../../../modules/person/context';
import {BaseDialogAwareSignInButtonErrorHandling} from '../base-dialog-aware-error-handling';
import {BaseDialogAwareSuccessHandling} from '../base-dialog-aware-success-handling';
import {LoginDialogCloseReason} from '../login-dialog';
import {personResource} from '../../../modules/person/person-resource';

export type ErrorObj = {
    readonly phoneNumberSuffixError?: string;
    readonly carrierIdError?: string;
    readonly planBillingDayError?: string;
}
export type SetErrorFunction = (
    errorObj: ErrorObj,
) => void;

interface MeContactObject {
    updateAction: string;
    phoneNumberPrefix: string;
    phoneNumberSuffix: string;
    carrier: number;
    planBillingDay?: string;
}

export async function handleSetMeContactFormSubmit(
    id: number,
    phoneNumberPrefix: number,
    phoneNumberSuffix: string,
    carrierId: number,
    planBillingDay: string,
    phoneNumberLength,
    dispatch: PersonDispatch,
    setError: SetErrorFunction,
    showLoadingIndicator: () => void,
    hideLoadingIndicator: () => void,
    closeDialog: (reason: LoginDialogCloseReason) => void,
    closeDialogOnSuccess: boolean,
    closeDialogOnFailure: boolean,
    navigate: NavigateFunction,
    t: (key: string) => string
): Promise<void> {
    let validationError = false;
    let errorObj = {};

    if (!phoneNumberSuffix || 
            phoneNumberSuffix.length < phoneNumberLength || 
            phoneNumberSuffix.length > phoneNumberLength || 
            isNaN(parseInt(phoneNumberSuffix))) {
        errorObj = {...errorObj, phoneNumberSuffixError: t('auth.invalid-phone-number-error')};
        validationError = true; 
    } 
    if (isNaN(carrierId) || carrierId < 0) {
        errorObj = {...errorObj, carrierIdError: t('auth.must-select-carrier-error')};
        validationError = true;
    } 
    if(planBillingDay) {
        const numberRegex = /\D/;
        const planDay = parseInt(planBillingDay);
        if (numberRegex.test(planBillingDay) || planDay < 1 || planDay > 30) {
            errorObj = {...errorObj, planBillingDayError: t('auth.billing-day-info')};
            validationError = true;
        }
    }

    if(validationError) {
        setError(errorObj);
        return;
    }

    let meContactObject: MeContactObject = {
        updateAction: "upsert_phone_number",
        phoneNumberPrefix: `+${phoneNumberPrefix}`,
        phoneNumberSuffix: phoneNumberSuffix,
        carrier: carrierId
    }
    if(planBillingDay) 
        meContactObject = {...meContactObject, planBillingDay: planBillingDay};

    showLoadingIndicator();
    try {
        const person = await personResource.update(id, meContactObject);

        dispatch({type: 'person/LOGIN_SUCCESS', person, signup: true});
        BaseDialogAwareSuccessHandling(closeDialogOnSuccess, closeDialog);
    } catch (e) {
        //TODO: We should manage api v2 validation error here, using setError according to the field
        BaseDialogAwareSignInButtonErrorHandling(
            e,
            navigate,
            closeDialog,
            closeDialogOnFailure,
            hideLoadingIndicator
        );
    }
}
