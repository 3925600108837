import {GG_JS_ID, GG_JS_SRC, GoogleIdentityProvider} from './google-provider';
import {initializeGoogleProvider} from './initialize-google-provider';
import {useIdentifyProvider} from '../base-use-provider';
import {useCallback} from 'react';

/**
 * Hook to expose the google provider instance.
 * Handles gapi load and initialization.
 *
 * This allows to wait for google to be ready in the "ui (react) layer"
 * because we load the google platform on demand
 *
 * If something happen when loading and initializing google, the reason will be in the "error" slot
 *
 * @param clientId
 */
export function useGoogleProvider(
    clientId: string,
    handleSignInResponse,
    handleErrorResponse
): [boolean, GoogleIdentityProvider, Error] {
    const initializeGoogleProviderWrapper = useCallback(() => {
        return initializeGoogleProvider(clientId, handleSignInResponse, handleErrorResponse);
        
    }, [clientId]);
    return useIdentifyProvider<GoogleIdentityProvider>(
        initializeGoogleProviderWrapper,
        GG_JS_ID,
        GG_JS_SRC,
        'google'
    );
}
