import {
    ChangeEventHandler,
    forwardRef,
    MouseEventHandler,
    ReactElement,
} from 'react';
import {
    ErrorText,
    InputRoot,
    StyledInput,
    StyledInputProps,
    StyledLabel,
    TextFieldRoot,
} from './text-field.styles';

export type TextFieldProps = {
    /**
     * If `true`, the input element will be disabled.
     */
    disabled?: boolean;

    /**
     * The text to show as a label for the input.
     */
    label?: string;

    /**
     * The input name attr
     */
    name?: string;

    /**
     * Allows full customization of the label element
     */
    labelElement?: ReactElement;

    /**
     * Sets the initial value for the input element
     */
    value?: string | ReadonlyArray<string> | number;

    /**
     * The default input element value. Use when the component is not controlled.
     */
    defaultValue?: string | ReadonlyArray<string> | number;
    /**
     * The text used as a placeholder
     */
    placeholder?: string;
    /**
     * The text to show as error description
     */
    errorText?: string;

    /**
     * Allows full customization of the error element
     */
    errorElement?: ReactElement;

    /**
     * Callback fired when the value is changed.
     */
    onChange?: ChangeEventHandler<HTMLInputElement>;

    /**
     * Callback fired when the input is clicked
     */
    onClick?: MouseEventHandler<HTMLInputElement>;

    /**
     * The id of the `input` element. Also, it is passed to the `htmlFor` props of the default `label` element.
     */
    id?: string;

    /**
     * Allows further customization of the root (container) element.
     */
    className?: string;

    /**
     * The input type
     */
    inputType?: string;

    /**
     * A component that will be placed at the end "inside" the input
     */
    endAdornment?: ReactElement;

    /**
     * A component that will be placed at the start "inside" the input
     */
    startAdornment?: ReactElement;
} & StyledInputProps;

/**
 * Basic input component
 *
 * The label and error elements can be customized via `labelElement`  and `errorElement` respectively.
 *
 * @example
 * Custom label and error
 *
 * const MyBlueBorderedLabel = <div style={{border: '1px solid blue'}}>{'Look at me!'}</div>
 * const MyRedBorderedError = <div style={{border: '1px solid red'}}>{"You've made a terrible mistake, Karen!"}</div>
 * <TextField
 *  labelElement={<MyBlueBorderedLabel />}
 *  errorElement={<MyRedBorderedError />}
 *  />
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    function renderTextField(
        {
            error = false,
            disabled = false,
            label,
            value,
            defaultValue,
            placeholder,
            errorText,
            onChange,
            onClick,
            errorElement,
            labelElement,
            id,
            className,
            inputType,
            endAdornment,
            name,
            startAdornment,
        }: TextFieldProps,
        ref
    ): JSX.Element {
        const labelNode =
            label && label !== '' ? (
                <StyledLabel data-testid="text-field-label" htmlFor={id}>
                    {label}
                </StyledLabel>
            ) : labelElement ? (
                labelElement
            ) : null;
        const errorNode =
            error && errorElement ? (
                errorElement
            ) : (
                <ErrorText data-testid="text-field-error" show={error && errorText}>
                    {errorText}
                </ErrorText>
            );
        return (
            <TextFieldRoot
                error={error}
                className={className}
                data-testid="text-field-root"
            >
                {labelNode}
                <InputRoot error={error} disabled={disabled}>
                    {startAdornment}
                    <StyledInput
                        data-testid="text-field-input"
                        id={id}
                        name={name}
                        ref={ref}
                        error={error}
                        disabled={disabled}
                        value={value}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        onClick={onClick}
                        placeholder={placeholder}
                        type={inputType}
                    ></StyledInput>
                    {endAdornment}
                </InputRoot>

                {errorNode}
            </TextFieldRoot>
        );
    }
);
