import Cookies from 'universal-cookie';
import {get} from '../../lib/utm';
import {getUtmCookieOptions} from '../util/cookie-preferences/get-utm-cookie-options';
import {Person} from './person-resource';
import {postSigninActions} from './post-signin-actions';
import {sessionResource} from './session-resource';

export async function personLogin(
    email: string,
    password: string,
    remember: boolean,
    cookies: Cookies,
    queryString: string
): Promise<Person> {
    const utms = get(cookies, queryString, getUtmCookieOptions());

    const person = await sessionResource.create({
        email,
        password,
    });

    await postSigninActions(
        person.data,
        utms,
        {
            remember,
        },
        cookies
    );
    return person;
}
