import React, { createContext, useState } from 'react';
import { Carrier } from '../common/models/country';

export type CarrierContextType = {
    selectedCarrier: Carrier | undefined;
    setSelectedCarrier: React.Dispatch<React.SetStateAction<Carrier | undefined>>;
};

export const CarrierContext = createContext<CarrierContextType>({
    selectedCarrier: undefined,
    setSelectedCarrier: () => {},
});

export const CarrierProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [selectedCarrier, setSelectedCarrier] = useState<Carrier | undefined>(undefined);

    return (
        <CarrierContext.Provider value={{ selectedCarrier, setSelectedCarrier }}>
            {children}
        </CarrierContext.Provider>
    );
};