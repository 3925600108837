import {AxiosResponse} from 'axios';
import {
    ApiResource,
    ApiReturn,
    axiosInstanceV2,
    CreateResource,
    NoErrorResponseType,
} from '../../lib/resources/resource';
import {PersonAccountType} from '../common/models';
import {FonomaError} from '../util/fonoma-error';
import {Person} from './person-resource';

export type SessionCreateIn = {
    email: string;
    password: string;
};

export class LoginFailed extends FonomaError {}

export class PersonTypeError extends FonomaError<ApiReturn> {
    constructor(
        public type: PersonAccountType,
        message?: string,
        wrapped?: ApiReturn
    ) {
        super(message, wrapped);
    }
}

export class SessionResource extends CreateResource<SessionCreateIn, Person> {
    translateToError(
        response: AxiosResponse<ApiResource>
    ): FonomaError | NoErrorResponseType {
        const apiReturn = response.data;

        if (apiReturn?.errors?.[0]?.details?.person_type) {
            return new PersonTypeError(
            apiReturn.errors[0].details.person_type,
                apiReturn.errors[0].message,
                apiReturn.errors[0]
            );
        } else return new LoginFailed(apiReturn.errors[0].message, apiReturn);
    }
}

export const sessionResource = new SessionResource('sessions', axiosInstanceV2);
