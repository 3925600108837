import {AxiosResponse} from 'axios';
import {
    ApiResource,
    ApiReturn,
    NoErrorResponseType,
    Resource,
} from '../../lib/resources/resource';
import {IRechargeRequestProcessor} from '../common/models/recharge-request-processor/recharge-request-processor';
import {FonomaError} from '../util/fonoma-error';

export interface IRechargeRequestAccept {
    acceptRechargeRequest: boolean;
}
export interface IRechargeRequestProcessorApi
    extends ApiResource,
        IRechargeRequestProcessor {}

export class InvalidAction extends FonomaError<ApiReturn> {
    errorCode: number;
    constructor(message: string, public wrapped: ApiReturn) {
        super(message);
        this.errorCode = this.wrapped.code;
    }
}
export class RechargeRequestResource extends Resource<
    never,
    never,
    never,
    never,
    IRechargeRequestAccept,
    IRechargeRequestProcessorApi
> {
    static readonly INVALID_ACTION = -400;

    public translateToError(
        response: AxiosResponse<ApiResource>
    ): FonomaError<ApiReturn> | Error | NoErrorResponseType {
        const apiReturn = response.data.apiReturn;
        const {code, msg} = apiReturn;
        if (code === RechargeRequestResource.INVALID_ACTION) {
            return new InvalidAction(msg, apiReturn);
        }
        return super.translateToError(response);
    }
}

export const rechargeRequestProcessorResource = new RechargeRequestResource(
    'recharge_requests'
);
