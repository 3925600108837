import React, {useContext} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {DialogAwareGoogleSignInButton} from './google/google-sign-in-flow';
import {DialogAwareAppleSignInButton} from './apple/apple-sign-in-flow';
import {DialogAwareFacebookSignInButton} from './facebook/facebook-sign-in-flow';
import {
    BoxContainer,
    FlexContainer,
    Heading as H1,
    P,
    StartScreenSpacer,
} from './styles';
import {ConnectedEmailButton} from './email/connected-email-button';
import styled from 'styled-components';
import {CountryContext} from '../../modules/login/country-context';

const Container = styled(BoxContainer)`
    margin-top: 16px;
`;

/**
 * The initial screen of the login flow.
 *
 * This component should have no logic
 * See `DialogAwareGoogleSignInButton` for how to connect a component to the login flow
 */
export function StartScreen(props: {
    closeDialogOnFailure: boolean;
    closeDialogOnSuccess: boolean;
}): JSX.Element {
    const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;

    const {t} = useTranslation('auth');
    const {closeDialogOnFailure, closeDialogOnSuccess} = props;
    const country = useContext(CountryContext);

    return (
        <>
        {country && 
        <FlexContainer data-testid="start-screen">
            <div>
                <H1>{t('auth:auth.get-started-with') + PROJECT_NAME}</H1>
            </div>
            <StartScreenSpacer />
            <BoxContainer>
                <DialogAwareFacebookSignInButton
                    closeDialogOnFailure={closeDialogOnFailure}
                    closeDialogOnSuccess={closeDialogOnSuccess}
                />
            </BoxContainer>
            <Container>
                <DialogAwareGoogleSignInButton
                    closeDialogOnFailure={closeDialogOnFailure}
                    closeDialogOnSuccess={closeDialogOnSuccess}
                />
            </Container>
            <Container>
                <DialogAwareAppleSignInButton
                    closeDialogOnFailure={closeDialogOnFailure}
                    closeDialogOnSuccess={closeDialogOnSuccess}
                />
            </Container>
            <Container>
                <ConnectedEmailButton />
            </Container>
        </FlexContainer>
        }
        </>
    );
}
