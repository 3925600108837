import Rollbar from 'rollbar';
import {track} from '../analytics/track';
import {Person} from '../person/person-resource';
import {EVENTS, TOAST_TYPES} from '../util/constants';
import {appendMessageToUrl} from '../util/utilities/append-message-to-url';
import {RechargeRequestProcessingResult} from './recharge-request-processing-result';
import {InvalidAction, rechargeRequestProcessorResource} from './resource';

export const processRechargeRequest = async (
    rechargeRequestId: number,
    personState: {person?: Person; isLoggedIn: boolean},
    rollbar: Rollbar
): Promise<RechargeRequestProcessingResult> => {
    try {
        const rechargeRequestResponse =
            await rechargeRequestProcessorResource.update(rechargeRequestId, {
                acceptRechargeRequest: true,
            });

        await track(personState, EVENTS.RECHARGE_REQUEST_ACCEPTED, {
            rechargeRequestId,
        });

        return {
            success: true,
            redirectTo: rechargeRequestResponse.sendRechargePermalink,
        };
    } catch (error) {
        const msg = error instanceof InvalidAction ? error.message : null;

        const redirectUrl = msg
            ? appendMessageToUrl('/', msg, TOAST_TYPES.ERROR)
            : '/';

        
        rollbar.error('Error processing recharge request', error);

        return {
            success: false,
            redirectTo: redirectUrl,
        };
    }
};
