import {ReactNode} from 'react';
import {AppleIdentityProvider} from '../../../lib/identity-providers/apple/apple-provider';
import {GoogleIdentityProvider} from '../../../lib/identity-providers/google/google-provider';
import {BaseConnectedSignInButton} from './base-connected-sign-in-button';
import {BaseConnectedSignInButtonProps} from './base-connected-button-props';

export interface BaseConnectedIconicSignInButtonProps<
    TIdentityProviderClass = AppleIdentityProvider | GoogleIdentityProvider
> extends BaseConnectedSignInButtonProps<TIdentityProviderClass> {
    iconComponent: ReactNode;
}

/**
 * Renders a connected iconic sign in button (like the ones we use for Apple, Facebook and google).
 *
 * It handles all the common logic of the button (showing the loading indicator,
 * setting/removing the busy status, calling the signIn function, logging in the user,
 * calling the success of failure functions).
 *
 * @param useProvider
 * @param signInFunction
 * @param iconComponent
 * @param buttonText
 * @param dataTestId
 * @param onSignInSuccess
 * @param onSignInFailure
 * @constructor
 */
export function BaseConnectedIconicSignInButton<TIdentityProviderClass>({
    useProvider,
    signInFunction,
    iconComponent,
    buttonText,
    dataTestId,
    onSignInSuccess,
    onSignInFailure,
}: BaseConnectedIconicSignInButtonProps<TIdentityProviderClass>): JSX.Element {
    return BaseConnectedSignInButton<TIdentityProviderClass>({
        componentType: 'iconic',
        onSignInFailure: onSignInFailure,
        onSignInSuccess: onSignInSuccess,
        signInFunction: signInFunction,
        useProvider: useProvider,
        buttonText: buttonText,
        dataTestId: dataTestId,
        iconComponent: iconComponent,
    });
}
