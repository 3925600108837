import {forwardRef} from 'react';
import styled from 'styled-components';

const BaseButton = styled.button`
    appearance: none;
    outline: 0;
    display: inline-flex;
    padding: 11px 16px;
    border-radius: 24px;
    border-width: 0.5px;
    border-style: solid;
    border-color: ${({theme}): string => theme.colors.tiburon};
    background-color: ${({theme}): string => theme.colors.arroz};
    color: ${({theme}): string => theme.colors.ballena};
    font-weight: bold;
    font-size: ${({theme}): string =>
        `${theme.fontSizes.medium}${theme.unitsOfMeasure.px}`};
    text-align: left;
    line-height: 24px;
    &:hover {
        cursor: ${({disabled}): string => (disabled ? 'initial' : 'pointer')};
        background-color: ${({theme}): string => theme.colors.caballa};
    }
    
    &:active {
        background-color: ${({theme}): string => theme.colors.atun};
    }
`;

const ButtonLabel = styled.span`
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
`;

const IconContainer = styled.span`
    display: inherit;
    margin-right: 16px;
`;

interface ButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    icon?: React.ReactNode;
    children?: React.ReactNode;
}

export const IconicButton = forwardRef<HTMLButtonElement, ButtonProps>(
    function renderButton({children, icon, ...other}: ButtonProps, ref) {
        const iconEl = icon && <IconContainer>{icon}</IconContainer>;
        return (
            <BaseButton ref={ref as any} {...other}>
                <ButtonLabel>
                    {iconEl}
                    {children}
                </ButtonLabel>
            </BaseButton>
        );
    }
);
