import {NavigateFunction} from 'react-router-dom';
import Cookies from 'universal-cookie';
import {PersonDispatch} from '../../../modules/person/context';
import {personSignUp} from '../../../modules/person/person-sign-up';
import {BaseDialogAwareSignInButtonErrorHandling} from '../base-dialog-aware-error-handling';
import {BaseDialogAwareSuccessHandling} from '../base-dialog-aware-success-handling';
import {LoginDialogCloseReason} from '../login-dialog';

export type SetErrorFunction = (
    fieldName: 'fullName' | 'password',
    error: string
) => void;

export async function handleCreateAccountFormSubmit(
    fullName: string,
    email: string,
    password: string,
    meContactFeature: boolean,
    dispatch: PersonDispatch,
    setError: SetErrorFunction,
    showLoadingIndicator: () => void,
    hideLoadingIndicator: () => void,
    closeDialog: (reason: LoginDialogCloseReason) => void,
    closeDialogOnSuccess: boolean,
    closeDialogOnFailure: boolean,
    navigate: NavigateFunction,
    t: (key: string) => string
): Promise<void> {
    if (fullName === '') {
        return setError('fullName', t('auth.enter-your-full-name-error'));
    }
    if (fullName && fullName.length < 3) {
        return setError('fullName', t('auth.full-name-short-error'));
    }
    if (password === '') {
        return setError('password', t('auth.enter-pass-error'));
    }
    showLoadingIndicator();
    try {
        const person = await personSignUp(
            fullName,
            email,
            password,
            new Cookies(document.cookie),
            document.location.search
        );

        const personDispatchType = (meContactFeature) ? 
            'person/SET_ME_CONTACT_REQUEST' : 'person/LOGIN_SUCCESS';
        dispatch({type: personDispatchType, person, signup: true});
        BaseDialogAwareSuccessHandling(closeDialogOnSuccess, closeDialog, false, null, meContactFeature, navigate);
    } catch (e) {
        BaseDialogAwareSignInButtonErrorHandling(
            e,
            navigate,
            closeDialog,
            closeDialogOnFailure,
            hideLoadingIndicator
        );
    }
}
