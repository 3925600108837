import {NavigateFunction} from 'react-router-dom';
import Rollbar from 'rollbar';
import Cookies from 'universal-cookie';
import {PersonAccountType} from '../../../modules/common/models';
import {PersonDispatch} from '../../../modules/person/context';
import {personLogin} from '../../../modules/person/person-login';
import {
    LoginFailed,
    PersonTypeError,
} from '../../../modules/person/session-resource';
import {BaseDialogAwareSignInButtonErrorHandling} from '../base-dialog-aware-error-handling';
import {BaseDialogAwareSuccessHandling} from '../base-dialog-aware-success-handling';
import {LoginDialogCloseReason} from '../login-dialog';

export async function handlePasswordFormSubmit(
    email: string,
    password: string,
    personDispatch: PersonDispatch,
    showLoadingIndicator: () => void,
    hideLoadingIndicator: () => void,
    closeDialog: (reason: LoginDialogCloseReason) => void,
    closeDialogOnFailure: boolean,
    closeDialogOnSuccess: boolean,
    setError: (error: boolean, text: string) => void,
    t: (key: string) => string,
    navigate: NavigateFunction,
    rollbar: Rollbar
): Promise<void> {
    showLoadingIndicator();
    try {
        const person = await personLogin(
            email,
            password,
            true,
            new Cookies(document.cookie),
            document.location.search
        );
        personDispatch({type: 'person/LOGIN_SUCCESS', person, signup: false});
        BaseDialogAwareSuccessHandling(closeDialogOnSuccess, closeDialog);
    } catch (e) {
        rollbar.error('Fonoma login failed', e);
        if (e instanceof PersonTypeError) {
            hideLoadingIndicator();
            switch (e.type) {
                case PersonAccountType.GG:
                    navigate(`/login/failure-google/${email}`);
                    break;
                case PersonAccountType.FB:
                    navigate(`/login/failure-facebook/${email}`);
                    break;
                case PersonAccountType.AP:
                    navigate(`/login/failure-apple/${email}`);
                    break;
            }
        } else if (e instanceof LoginFailed) {
            hideLoadingIndicator();
            setError(true, t('auth.wrong-password-error'));
        } else {
            BaseDialogAwareSignInButtonErrorHandling(
                e,
                navigate,
                closeDialog,
                closeDialogOnFailure,
                hideLoadingIndicator
            );
        }
    }
}
