import {FormEvent} from 'react';
import {NavigateFunction} from 'react-router-dom';
import Rollbar from 'rollbar';
import Cookies from 'universal-cookie';
import {
    AuthResult,
    IdentityProviders,
} from '../../../../lib/identity-providers/identity-provider';
import {PersonDispatch} from '../../../../modules/person/context';
import {FacebookExtraData} from '../../../../modules/person/facebook-sign-in/facebook-sign-in';
import {
    OauthSessionAccountAlreadyExists,
    OauthSessionBlacklistedDomain,
} from '../../../../modules/person/oauth-session-resource';
import {personSignIn} from '../../../../modules/person/person-sign-in';
import {emailIsInvalid} from '../../../../modules/util/email-is-invalid';
import {BaseDialogAwareSignInButtonErrorHandling} from '../../base-dialog-aware-error-handling';
import {BaseDialogAwareSuccessHandling} from '../../base-dialog-aware-success-handling';
import {LoginDialogCloseReason} from '../../login-dialog';

export interface SubmitFormCallbackArgs {
    e: FormEvent;
    t: (str: string) => string;
    fbToken: string;
    email: string;
    setError: (error: string) => void;
    showLoadingIndicator: () => void;
    hideLoadingIndicator: () => void;
    meContactFeature: boolean;
    personDispatch: PersonDispatch;
    cookies: Cookies;
    queryString: string;
    closeDialogOnSuccess: boolean;
    closeDialogOnFailure: boolean;
    closeDialog: (reason: LoginDialogCloseReason) => void;
    navigate: NavigateFunction;
    emailAndPersonTypeMismatch?: boolean;
    onEmailAndPersonTypeMismatch?: () => void;
    rollbar: Rollbar;
}

/**
 * Callback for submitForm on FacebookEmailRequiredFlow
 * @param e
 * @param t
 * @param fbToken
 * @param email
 * @param setError
 * @param showLoadingIndicator
 * @param hideLoadingIndicator
 * @param meContactFeature
 * @param personDispatch
 * @param cookies
 * @param queryString
 * @param closeDialogOnSuccess
 * @param closeDialogOnFailure
 * @param closeDialog
 * @param navigate
 * @param emailAndPersonTypeMismatch
 * @param onEmailAndPersonTypeMismatch
 * @param rollbar
 */
export async function submitFormCallbackFacebookEmailRequiredFlow({
    e,
    t,
    fbToken,
    email,
    setError,
    showLoadingIndicator,
    hideLoadingIndicator,
    meContactFeature,
    personDispatch,
    cookies,
    queryString,
    closeDialogOnSuccess,
    closeDialogOnFailure,
    closeDialog,
    navigate,
    emailAndPersonTypeMismatch,
    onEmailAndPersonTypeMismatch,
    rollbar,
}: SubmitFormCallbackArgs): Promise<void> {
    e.preventDefault();
    if (!email) {
        setError(t('auth.enter-email-error'));
        return;
    }
    if (emailIsInvalid(email)) {
        setError(t('auth.email-not-valid-error'));
        return;
    }
    showLoadingIndicator();

    const newAuthResult = {
        token: fbToken,
        kind: IdentityProviders.Facebook,
        isAutoLogin: false,
        extra: {
            email: email,
        },
    } as AuthResult<FacebookExtraData>;
    try {
        const response = await personSignIn<FacebookExtraData>(
            newAuthResult,
            {
                remember: true,
                cookies,
                queryString,
            }
        );
        const {signup, person} = response.data;

        const personDispatchType = (meContactFeature && signup) ? 
            'person/SET_ME_CONTACT_REQUEST' : 'person/LOGIN_SUCCESS';
        // update state
        personDispatch({
            type: personDispatchType,
            person,
            signup,
        });

        BaseDialogAwareSuccessHandling(
            closeDialogOnSuccess,
            closeDialog,
            emailAndPersonTypeMismatch,
            onEmailAndPersonTypeMismatch,
            meContactFeature && signup,
            navigate
        );
        
    } catch (e) {
        rollbar.error('Facebook email required flow failed', e);
        // For invalid emails we keep the user on the current screen
        if (
            e instanceof OauthSessionAccountAlreadyExists ||
            e instanceof OauthSessionBlacklistedDomain
        ) {
            setError(e.message);
            hideLoadingIndicator();
            return;
        }
        BaseDialogAwareSignInButtonErrorHandling(
            e,
            navigate,
            closeDialog,
            closeDialogOnFailure,
            hideLoadingIndicator
        );
    }
}
