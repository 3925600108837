import createSvgIcon from './svg-icon';

export const BackIcon = createSvgIcon(
    <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.207.293c.39.39.39 1.024 0 1.414L3.914 6l4.293 4.293c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0l-5-5c-.39-.39-.39-1.024 0-1.414l5-5c.39-.39 1.024-.39 1.414 0z"
        clipRule="evenodd"
    />,
    'Back'
);
