import {Route, Routes} from 'react-router-dom';
import {PersonAccountType} from '../../../modules/common/models';
import {CreateNewAccountScreen} from './create-new-account-screen';
import {EmailScreen} from './email-screen';
import {LoginFailureOauthAccountScreen} from './login-failure-oauth-account-screen';
import {EmailSentScreen} from './password-reset/email-sent-screen';
import {PasswordResetScreen} from './password-reset/password-reset-screen';
import {PasswordScreen} from './password-screen';
import {SetMeContactScreen} from './set-me-contact-screen';

export function EmailLoginFlow(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<EmailScreen />} />
            <Route path="/password/:email" element={<PasswordScreen />} />
            <Route
                path="/password-reset/:email"
                element={<PasswordResetScreen />}
            />
            <Route path="/email-sent/:email" element={<EmailSentScreen />} />
            <Route path="/create/:email" element={<CreateNewAccountScreen />} />
            <Route
                path="/set-me-contact/"
                element={<SetMeContactScreen />}
            />
            <Route
                path="/failure-facebook/:email"
                element={
                    <LoginFailureOauthAccountScreen
                        type={PersonAccountType.FB}
                    />
                }
            />
            <Route
                path="/failure-google/:email"
                element={
                    <LoginFailureOauthAccountScreen
                        type={PersonAccountType.GG}
                    />
                }
            />
            <Route
                path="/failure-apple/:email"
                element={
                    <LoginFailureOauthAccountScreen
                        type={PersonAccountType.AP}
                    />
                }
            />
        </Routes>
    );
}
