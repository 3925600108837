/**
 * See `models.person.Person.status`
 */
export enum PersonStatus {
    Active = 1,
    Blocked = 2,
    FraudVerified = 3,
    Canceled = 4,
    FraudSuspect = 5,
}

/**
 * See `models.person.Person.type`
 */
export enum PersonAccountType {
    FN = 'fn',
    FB = 'fb',
    GG = 'gg',
    AP = 'ap',
}

/**
 * TODO: This should be defined in config file
 */
export enum LanguageChoices {
    ES = 'es',
    EN = 'en',
}

// this doesnt belong here
export enum PersonApiErrorCodes {
    //AccountAlreadyExists = -2,
    AccountAlreadyExists = -16,
    CurrentPasswordIncorrect = -2,
    IpCountryAccountAlreadyExists = -3,
    BlacklistedDomain = -4,
    UnknownFileExtension = -6,
    UpdatePictureError = -7,
    IncorrectParameters = -8,
    InvalidEmailAddress = -9,
    NameTooShort = -10,
    InvalidPaymentMethodOwner = -11,
    PaymentMethodNotAllowedInCountry = -12,
    ExpiredPaymentMethod = -13,
    InvalidArguments = -14,
    InvalidPaymentMethod = -15,
}

export enum Currencies {
    EUR = 'EUR',
    USD = 'USD',
}
