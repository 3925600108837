import {AxiosError} from 'axios';
import {PromoCodeProcessingResult} from './promo-code-processing-result';
import {appendMessageToUrl} from '../util/utilities/append-message-to-url';
import {TOAST_TYPES} from '../util/constants';
export const PROMO_CODE_DOES_NOT_EXIST = -200;
export const USER_INVITING_HIMSELF = -300;
export const TOO_MANY_REDEEMS = -400;
export const USER_ALREADY_INVITED = -500;
export const REDEEMER_NOT_NEWCOMER = -600;
import {PromoCodeCanNotBeRedeemed} from './resource';

/**
 * Returns the PromoCodeProcessingResult after an error and the errorCode (to be tracked)
 *
 * @param error The error.
 */
export function getResultAndEventAfterPromoCodeProcessingError(
    error: PromoCodeCanNotBeRedeemed | AxiosError,
    landingName: string
): {finalResult: PromoCodeProcessingResult; errorCode: number} {
    let msg: string;
    let errorCode: number;
    if (error instanceof PromoCodeCanNotBeRedeemed) {
        msg = error.message;
        errorCode = error.errorCode;
    } else {
        msg = null;
        errorCode = -1;
    }
    const basePath = landingName ? '/' : '/account/promos_discounts';
    return {
        finalResult: {
            success: false,
            redirectTo: msg
                ? appendMessageToUrl(basePath, msg, TOAST_TYPES.ERROR)
                : null,
        },
        errorCode: errorCode,
    };
}
