import {useRollbar} from '@rollbar/react';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import {
    ChangeEvent,
    FormEvent,
    SyntheticEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {analytics} from '../../../modules/analytics/analytics';
import {useGlobalLoadingIndicator} from '../../../modules/global-loading-indicator/context';
import {usePersonDispatch} from '../../../modules/person/context';
import {PAGE_EVENTS} from '../../../modules/util/constants';
import {Button} from '../../buttons/button';
import {ForgotPasswordLabel} from '../../forgot-password-label';
import {useLoginDialogContext} from '../login-dialog';
import {
    BoxContainer,
    FlexContainer,
    FullSizeTextField,
    Heading as H2,
    P,
    ShowPasswordButton,
} from '../styles';
import {handlePasswordFormSubmit} from './handle-password-form-submit';

const Div = styled.div`
    margin-bottom: 40px;
`;

export function PasswordScreen(): JSX.Element {
    const {t} = useTranslation('auth');
    const {email} = useParams<{readonly email: string}>();
    const dispatch = usePersonDispatch();
    const navigate = useNavigate();
    const {closeDialog, closeDialogOnFailure, closeDialogOnSuccess} =
        useLoginDialogContext();

    const {hideLoadingIndicator, showLoadingIndicator} =
        useGlobalLoadingIndicator();

    const rollbar = useRollbar();
    const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;

    const [passwordState, setPassword] = useState<{
        readonly password: string;
        readonly showPassword: boolean;
    }>({
        password: '',
        showPassword: false,
    });
    const [errorState, setErrorState] = useState<{
        error: boolean;
        text?: string;
    }>({
        error: false,
    });

    const handleChange = ({target}: ChangeEvent<HTMLInputElement>) => {
        const {value} = target;
        setPassword({...passwordState, password: value});
        if (errorState.error) {
            setErrorState({error: false});
        }
    };

    const setError = (error: boolean, text?: string) =>
        setErrorState({error, text});
    const {password, showPassword} = passwordState;
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handlePasswordFormSubmit(
            email,
            password,
            dispatch,
            showLoadingIndicator,
            hideLoadingIndicator,
            closeDialog,
            closeDialogOnFailure,
            closeDialogOnSuccess,
            setError,
            t,
            navigate,
            rollbar
        );
    };

    // auto focus
    const passwordInputRef = useRef<HTMLInputElement>();
    useEffect(() => {
        passwordInputRef.current.focus();
        void analytics.page(PAGE_EVENTS.LOGIN_PASSWORD_PAGE);
        
    }, []);

    const toggleShowPassword = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setPassword({...passwordState, showPassword: !showPassword});
    };

    // set focus and put the cursor at the end again.
    // happens only when showPassword changes
    useEffect(() => {
        passwordInputRef.current.selectionStart =
            passwordInputRef.current.selectionEnd = password.length;
        passwordInputRef.current.focus();
        
    }, [showPassword]);

    const handleForgotPassword = () => {
        navigate(`/login/password-reset/${email}`);
    };

    return (
        <FlexContainer data-testid="enter-password-screen">
            <Div>
                <H2 data-testid="welcome-heading">
                    {t('auth:auth.welcome-heading')}
                </H2>
                <P data-testid="enter-pass-message">
                    <Trans
                        i18nKey={'auth:auth.enter-pass-welcome'}
                        values={{user_email: email}}
                        components={{strong: <strong />}}
                    />
                </P>
            </Div>

            <BoxContainer>
                <form noValidate onSubmit={handleSubmit}>
                    <BoxContainer>
                        <FullSizeTextField
                            ref={passwordInputRef}
                            id="password"
                            value={password}
                            onChange={handleChange}
                            labelElement={
                                <ForgotPasswordLabel
                                    labelText={t('auth:auth.password-label')}
                                    linkText={t(
                                        'auth:auth.forgot-password-label'
                                    )}
                                    onClick={handleForgotPassword}
                                    htmlFor="password"
                                />
                            }
                            inputType={showPassword ? 'text' : 'password'}
                            error={errorState.error}
                            errorText={errorState.text}
                            endAdornment={
                                <ShowPasswordButton
                                    onClick={toggleShowPassword}
                                    type="button"
                                    data-testid="show-password-btn"
                                >
                                    {showPassword
                                        ? t('auth:auth.hide-label')
                                        : t('auth:auth.show-label')}
                                </ShowPasswordButton>
                            }
                        />
                    </BoxContainer>
                    <BoxContainer>
                        <Button
                            type="submit"
                            fullWidth
                            disabled={password === ''}
                            data-testid="login-to-fonoma-btn"
                        >
                            {t('auth:auth.login-to') + PROJECT_NAME}
                        </Button>
                    </BoxContainer>
                </form>
            </BoxContainer>
        </FlexContainer>
    );
}
