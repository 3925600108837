import {BaseConnectedIconicSignInButton} from '../base-connected-button/base-connected-iconic-sign-in-button';
import {BaseConnectedPrimarySignInButton} from '../base-connected-button/base-connected-primary-sign-in-button';
import {ConnectedSignInButtonProps} from '../base-connected-button/base-connected-button-props';
import useTranslation from 'next-translate/useTranslation';
import {AppleIdentityProvider} from '../../../lib/identity-providers/apple/apple-provider';
import {useAppleProvider} from '../../../lib/identity-providers/apple/use-apple-provider';
import {OauthSessionCreateResult} from '../../../modules/person/oauth-session-resource';
import {appleSignIn} from '../../../modules/person/apple-sign-in/apple-sign-in';
import {AppleIcon} from '../../common/icons/apple';
import React, {useCallback} from 'react';

export interface AppleConnectedSignInButtonProps
    extends ConnectedSignInButtonProps {
    fullName?: string;
}

/**
 * This component connects an apple button with the sign in logic.
 
 * This component will initialize the Apple api and, when clicked, will launch the Apple sign in dialog.
 *
 * If the param fullName is not present, the login might fail in our server, in that case, we'll
 * redirect the user to the AppleFullNameRequiredFlow.
 *
 * Loading indicator behaviour:
 *
 * If the login dialog gives away the control of the UI, the loading indicator is not dismissed
 * 1because the invoker of the login flow might want to do something else (like a redirection).
 * - If the login is successful, the loading indicator is not dismissed.
 * - If the login fails, but closeDialogOnFailure is True, the loading indicator is not dismissed.
 * - If the login fails, but closeDialogOnFailure is False, the loading indicator is dismissed.
 *
 * If present, validateInput will be executed before starting the process of signIn with the Apple.
 * If validateInput raised an error, handleValidationError will receive this error as an argument.
 *
 * @param onSignInSuccess
 * @param onSignInFailure
 * @param componentType
 * @param fullName
 * @param validateInput: Function that will be executed before starting the process of signIn with the Apple.
 * @param handleValidationError: If validateInput raised an error, this function will receive this error as an argument.
 * @constructor
 */
export function ConnectedAppleSignInButton({
    onSignInSuccess,
    onSignInFailure,
    componentType,
    fullName,
    validateInput,
    handleValidationError,
}: AppleConnectedSignInButtonProps): JSX.Element {
    const APPLE_REDIRECT_URI = process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI;
    const APPLE_CLIENT_ID = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID;
    const {t} = useTranslation('auth');
    const useAppleProviderWrapper = (): [
        boolean,
        AppleIdentityProvider,
        Error
    ] => {
        return useAppleProvider(APPLE_CLIENT_ID, APPLE_REDIRECT_URI);
    };

    const appleSignInWrapper = useCallback(
        (
            provider: AppleIdentityProvider
        ): Promise<OauthSessionCreateResult> => {
            return appleSignIn(provider, fullName);
        },
        [fullName]
    );

    switch (componentType) {
        case 'iconic':
            return BaseConnectedIconicSignInButton<AppleIdentityProvider>({
                useProvider: useAppleProviderWrapper,
                iconComponent: <AppleIcon />,
                signInFunction: appleSignInWrapper,
                buttonText: t('auth:auth.continue-with-apple'),
                dataTestId: 'Apple-button',
                onSignInFailure: onSignInFailure,
                onSignInSuccess: onSignInSuccess,
                validateInput: validateInput,
                handleValidationError: handleValidationError,
            });
        case 'primary':
            return BaseConnectedPrimarySignInButton<AppleIdentityProvider>({
                useProvider: useAppleProviderWrapper,
                signInFunction: appleSignInWrapper,
                buttonText: t('auth.continue-button'),
                dataTestId: 'Apple-button',
                onSignInFailure: onSignInFailure,
                onSignInSuccess: onSignInSuccess,
                validateInput: validateInput,
                handleValidationError: handleValidationError,
            });
    }
}
