import {camelCase} from 'lodash';
import Cookies from 'universal-cookie';
import {deepClone} from '../../lib/resources/resource';
import {get} from '../../lib/utm';
import {getUserPreferences} from '../util/cookie-preferences/get-user-preferences';
import {getUtmCookieOptions} from '../util/cookie-preferences/get-utm-cookie-options';
import {defaultPreferences} from '../util/cookie-preferences/preferences';
import {Person, personResource, PersonUtms} from './person-resource';
import {postSigninActions} from './post-signin-actions';

export async function personSignUp(
    fullName: string,
    email: string,
    password: string,
    cookies: Cookies,
    queryString: string
): Promise<Person> {
    const utms = get(cookies, queryString, getUtmCookieOptions());
    const personUtms: PersonUtms = deepClone(utms, camelCase);

    const {currency, lang} = getUserPreferences(cookies, defaultPreferences);
    const person = await personResource.create({
        ...personUtms,
        fullName,
        email,
        password,
        lang,
        currency,
    });
    await postSigninActions(person, utms, {
        autoLogin: false,
        remember: false,
        signupMethod: 'fonoma',
    });

    return person;
}
