import {
    AuthResult,
    IdentityProvider,
    IdentityProviders,
    UserCancelledFlow,
} from '../identity-provider';

export const AP_JS_ID = 'apple-id';
export const AP_JS_SRC =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

export interface AppleExtraData {
    apFullName: string;
}

/**
 * Apple identity provider implementation
 *
 * Creator must initialize gapi platform
 */
export class AppleIdentityProvider extends IdentityProvider<
    AppleSignInAPI.AuthI,
    {},
    AppleExtraData
> {
    /**
     * Apple sign in
     *
     * Calls Apple.signIn with the defaults.
     * id_token is in AuthResult.token
     *
     */
    async signIn(): Promise<AuthResult<AppleExtraData>> {
        try {
            const signInResponse = await this.client.signIn();
            return this.createAuthResult(signInResponse);
        } catch (e) {
            if (
                e.error === 'popup_closed_by_user' ||
                e.error === 'user_cancelled_authorize'
            ) {
                throw new UserCancelledFlow('User cancelled flow');
            }
            throw e;
        }
    }

    private createAuthResult(
        signInResponse: AppleSignInAPI.SignInResponseI
    ): AuthResult<AppleExtraData> {
        const baseAuthResult = {
            token: signInResponse.authorization.code,
            kind: IdentityProviders.Apple,
            isAutoLogin: !signInResponse.user,
        } as AuthResult<AppleExtraData>;
        if (signInResponse.user) {
            baseAuthResult.extra = {
                apFullName: `${signInResponse.user.name.firstName} ${signInResponse.user.name.lastName}`,
            };
        }
        return baseAuthResult;
    }
}
