import styled from 'styled-components';
import React from 'react';
import {StyledProps} from '../../modules/common';
import Link from 'next/link';
import {darken} from 'polished';
import isPropValid from '@emotion/is-prop-valid';

interface StyledButtonProps {
    secondary?: boolean;
    backgroundColor?: string;
    borderColor?: string;
    fontColor?: string;
}

interface BaseButtonProps extends StyledButtonProps {
    href?: string;
    onClick?: () => void;
    /* We need this prop in order to use "as" functionality. See https://github.com/emotion-js/emotion/issues/1137 */
    as?: string;
    fullWidth?: boolean;
}

/**
 * Fonoma's base button. Intended to be extended, although it can be used like this
 *
 * ````typescript
 * <Button onClick={() => console.log("Hello!")}>Hello!</Button>
 * ````
 */

const customShouldForwardProp = (prop) =>
    isPropValid(prop) &&
    !['fullWidth', 'backgroundColor', 'fontColor', 'borderColor'].includes(
        prop
    );

const Button = styled.button
    .withConfig({
        shouldForwardProp: customShouldForwardProp,
    })
    .attrs<BaseButtonProps, BaseButtonProps>(
        (props): Record<string, unknown> => {
            const mainButtonDefaultBackgroundColor =
                props.theme.colors.plancton;
            const mainButtonDefaultFontColor = props.theme.colors.arroz;
            const secondaryButtonDefaultBackgroundColor = 'transparent';
            const secondaryButtonDefaultFontColor = props.theme.colors.ballena;
            const secondaryButtonDefaultBorderColor =
                props.theme.colors.tiburon;

            const backgroundColor = props.backgroundColor
                ? props.backgroundColor
                : props.secondary
                  ? secondaryButtonDefaultBackgroundColor
                  : mainButtonDefaultBackgroundColor;
            const fontColor = props.fontColor
                ? props.fontColor
                : props.secondary
                  ? secondaryButtonDefaultFontColor
                  : mainButtonDefaultFontColor;
            const borderColor = props.borderColor
                ? props.borderColor
                : props.secondary
                  ? secondaryButtonDefaultBorderColor
                  : mainButtonDefaultBackgroundColor;
            return {
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                fontColor: fontColor,
            };
        }
    )`
    padding: 11px 16px;
    text-shadow: none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 100px;

    font-weight: bold;
    font-size: ${(props): string =>
        `${props.theme.fontSizes.medium}${props.theme.unitsOfMeasure.px}`};
    line-height: ${(props): string => `${props.theme.spacing.l}`};
    ${({fullWidth}) => (fullWidth ? 'width: 100%' : '')};

    background-color: ${(props): string => props.backgroundColor};
    border: 1px solid;
    border-color: ${(props): string => props.borderColor};

    color: ${(props): string => props.fontColor};

    transition: all 0.3s ease-out;

    &:hover {
        color: ${(props): string => darken('0.1', props.fontColor)};
        border-color: ${(props): string => darken('0.1', props.borderColor)};
        background-color: ${(props): string =>
            darken('0.1', props.backgroundColor)};
    }

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline-style: none;
    }
`;

interface LinkButtonProps extends StyledProps, StyledButtonProps {
    children: React.ReactNode;
    href: string;
    internalLink?: boolean;
}

/**
 * Renders a link that looks like a button.
 * If internalLink is true, it will use the Link component, if false, it will render a regular <a>
 * @param props
 * @constructor
 */
const LinkButton = (props: LinkButtonProps): JSX.Element => {
    const {
        href,
        internalLink,
        children,
        className,
        secondary,
        backgroundColor,
        borderColor,
        fontColor,
    } = props;
    if (internalLink) {
        return (
            <Link href={href} legacyBehavior>
                <Button
                    data-testid="button-internal-link"
                    backgroundColor={backgroundColor}
                    borderColor={borderColor}
                    href={href}
                    fontColor={fontColor}
                    as="a"
                    secondary={secondary}
                    className={className}
                >
                    {children}
                </Button>
            </Link>
        );
    }
    return (
        <Button
            data-testid="button-external-link"
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            fontColor={fontColor}
            as="a"
            href={href}
            secondary={secondary}
            className={className}
        >
            {children}
        </Button>
    );
};

export {Button, LinkButton};
