import {PropsWithChildren} from 'react';
import {MemoryRouter, Routes} from 'react-router-dom';
import {LoginFlowNav} from './login-flow-nav';

/**
 * Login/Signup flow wrapper
 *
 * Internally it uses a MemoryRouter for navigation.
 * [MemoryRouter](https://reactrouter.com/web/api/MemoryRouter) *
 *
 */
export function LoginFlow({children}: PropsWithChildren<{}>): JSX.Element {
    return (
        <MemoryRouter>
            <LoginFlowNav />
            <Routes>{children}</Routes>
        </MemoryRouter>
    );
}
