import {AppleIdentityProvider, AP_JS_ID, AP_JS_SRC} from './apple-provider';
import {useCallback} from 'react';
import {initializeAppleProvider} from './initialize-apple-provider';
import {useIdentifyProvider} from '../base-use-provider';

/**
 * Hook to expose the Apple provider instance.
 * Handles AppleId load and initialization.
 *
 * This allows to wait for Apple to be ready in the "ui (react) layer"
 * because we load the Apple platform on demand
 *
 * If something happen when loading and initializing Apple, the reason will be in the "error" slot
 *
 * @param clientId
 * @param redirectUri
 */
export function useAppleProvider(
    clientId: string,
    redirectUri: string
): [boolean, AppleIdentityProvider, Error] {
    const initializeAppleProviderWrapper = useCallback(() => {
        return new Promise<AppleIdentityProvider>((resolve) => {
            resolve(initializeAppleProvider(clientId, redirectUri));
        });
    }, [clientId, redirectUri]);
    return useIdentifyProvider<AppleIdentityProvider>(
        initializeAppleProviderWrapper,
        AP_JS_ID,
        AP_JS_SRC,
        'AppleID'
    );
}
