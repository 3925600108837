import createSvgIcon from './svg-icon';

export const EnvelopeIcon = createSvgIcon(
    <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
            fill="#002C48"
            d="M20 5l.1166211.00672773C20.6139598 5.06449284 21 5.48716416 21 6v12c0 .5522847-.4477153 1-1 1H4c-.55228475 0-1-.4477153-1-1V6l.00672773-.11662113C3.06449284 5.38604019 3.48716416 5 4 5h16zm-1 3l-7 6-7-6v9h14V8zm-2-1H7l5 4 5-4z"
        />
    </g>,
    'Envelope'
);
